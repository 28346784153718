import { Link } from '@mui/material';
import { Ref, forwardRef } from 'react';
import { Link as RouterDomLink } from 'react-router-dom';

interface RouterLinkProps extends React.ComponentProps<typeof Link> {
  url: string;
  state?: { [key: string]: unknown };
}
const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref: Ref<HTMLAnchorElement>) => {
  const { url, state, ...rest } = props;

  return <Link component={RouterDomLink} ref={ref} to={url} state={state} {...rest} />;
});

export { RouterLink };
