import { useState, useEffect, useCallback } from 'react';

function useLocalStorage<ValueType>(key: string, defaultValue?: ValueType) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);

    if (storedValue === null || storedValue === 'undefined') {
      localStorage.setItem(key, JSON.stringify(defaultValue));

      return defaultValue;
    }

    return JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = useCallback(
    (newValue: ValueType) => {
      setValue((currentValue: ValueType) => {
        const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;

        localStorage.setItem(key, JSON.stringify(result));

        return result;
      });
    },
    [key]
  );

  const removeValueInLocalStorage = () => {
    localStorage.removeItem(key);
    setValue(defaultValue);
  };

  return [value, setValueInLocalStorage, removeValueInLocalStorage];
}

export { useLocalStorage };
