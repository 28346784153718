import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import bash from 'highlight.js/lib/languages/bash';
import html from 'highlight.js/lib/languages/xml';
import scss from 'highlight.js/lib/languages/scss';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';

import 'highlight.js/styles/atom-one-dark-reasonable.css';

[javascript, bash, html, scss, css, json].forEach((lang) => hljs.registerLanguage(lang.name, lang));

hljs.configure({
  languages: ['javascript', 'jsx', 'sh', 'bash', 'html', 'scss', 'css', 'json'],
});

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).hljs = hljs;
}
