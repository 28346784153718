import { componentsOverrides } from '@diagrid/cloud-ui-shared/utils/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

type ThemeColorPresetsProps = {
  themeOptions: any;
  overrideComponents?: any[];
};

export function ThemeColorPresets({ children, themeOptions, overrideComponents }: PropsWithChildren<ThemeColorPresetsProps>) {
  const theme = createTheme(themeOptions);
  theme.components = componentsOverrides(theme, overrideComponents);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
