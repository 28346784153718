export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'always',
        color: 'primary.main',
      },
    },
  };
}
