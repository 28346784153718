import { CheckSharp, CopyAllSharp } from '@mui/icons-material';
import { Box, FormLabel, IconButton, SxProps, Theme, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism } from 'react-syntax-highlighter';
import { dracula, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { LanguageSyntax } from '../types';

type CodeBlockProps = {
  value: string;
  label?: string;
  showCopyButton?: boolean;
  language?: LanguageSyntax;
  displayValue?: string;
  // pass in a string of arrays and it will replace it with ********* only for display
  secrets?: string[];
  sx?: SxProps<Theme>;
  prysmCustomStyles?: {
    [key: string]: unknown;
  };
  [key: string]: unknown;
};

export function CodeBlock({
  value,
  displayValue,
  showCopyButton = true,
  language,
  label = '',
  secrets,
  sx,
  prysmCustomStyles = {},
  ...props
}: CodeBlockProps) {
  const theme = useTheme();
  const [state, setState] = useState({
    value: '',
    copied: false,
  });

  useEffect(() => {
    setState({ value, copied: false });
  }, [value]);

  const onCopy = () => {
    setState({ ...state, copied: true });
    setTimeout(() => {
      setState({ ...state, copied: false });
    }, 2000);
  };
  const isDarkMode = theme.palette.mode === 'dark';
  const background = isDarkMode ? theme.palette.grey[900] : '#fff';

  const hideSecrets = (text: string, secrets: string[]): string => {
    // Create a regular expression pattern to match all secrets
    const secretsPattern = secrets
      .map((secret) => {
        if (!secret) return null;
        return `(${secret})`;
      })
      .filter(Boolean)
      .join('|');
    if (!secretsPattern) return text;
    const pattern = new RegExp(secretsPattern, 'gi');

    return text.replace(pattern, '●●●●●●●●●●●●●●●●');
  };

  const getDisplayValue = useMemo(() => {
    const fallback = value ? value : ` `;
    const displayFallback = displayValue ? displayValue : fallback;

    if (!secrets || secrets.length < 0) return displayFallback;

    if (secrets && secrets.length > 0) {
      const withSecrets = hideSecrets(value, secrets);
      return withSecrets ? withSecrets : fallback;
    }

    return displayFallback;
  }, [secrets, displayValue, value]);

  return (
    <Box
      sx={{
        position: 'relative',
        background,
        border: `1px solid ${isDarkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
        borderRadius: '0.6rem',
        padding: '0 1rem',
        '& code': {
          backgroundColor: 'transparent !important',
          fontSize: isDarkMode ? '1rem' : '0.9rem',
        },
        '& pre': {
          padding: `0 ${showCopyButton ? 2.5 : 0}rem 0.5em 0 !important`,
          '&::-webkit-scrollbar': {
            height: '0.6rem',
            width: '0.6rem',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: '0.5rem',

            '&:hover': {
              background: theme.palette.primary.main,
            },
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent !important',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'transparent !important',
          },
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          borderRadius: 50,
          background: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[300],
          visibility: showCopyButton ? 'visible' : 'hidden',
          opacity: showCopyButton ? 1 : 0,
        }}
      >
        <CopyToClipboard text={state.value} onCopy={onCopy}>
          <IconButton>{state.copied ? <CheckSharp fontSize="medium" /> : <CopyAllSharp fontSize="medium" />}</IconButton>
        </CopyToClipboard>
      </Box>
      {label && (
        <FormLabel
          sx={{
            position: 'absolute',

            padding: '0.3rem 0.5rem',
            top: '-0.8rem',
            left: '0.5rem',
            fontSize: '0.8rem',
            '&: after': {
              content: '""',
              position: 'absolute',
              top: 'calc(50% - 3px)',
              zIndex: 1,
              left: 0,
              width: '100%',
              height: '50%',
              background,
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              zIndex: 2,
            }}
          >
            {label}
          </Box>
        </FormLabel>
      )}

      <Prism
        data-cy="code-block"
        language={language}
        style={isDarkMode ? dracula : oneLight}
        customStyle={{
          paddingRight: '2.5rem',
          background,
          borderRadius: '0',
          marginTop: '1em',
          zIndex: 1,
          ...{ ...prysmCustomStyles },
        }}
        wrapLines
        {...props}
      >
        {getDisplayValue}
      </Prism>
    </Box>
  );
}
