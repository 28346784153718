import { ReportSharp } from '@mui/icons-material';
import { Box, CircularProgress, Container, Paper, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DiagridLogo } from 'src/components/DiagridLogo';
import { Logo } from 'src/components/Logo';
import { useLocales } from 'src/hooks/useLocales';
import { Page } from '../../components/Page';
import { LoginForm } from '../../sections/auth/LoginForm';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const FooterStyle = styled('footer')(({ theme }) => ({
  bottom: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(7, 5, 0, 7),
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '20vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { translate } = useLocales();
  const theme = useTheme();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const hasCode = query.get('code');
  const rejected = query.get('error_description');

  const getContentByStatus = (rejected: string) => {
    switch (rejected) {
      case 'not-invited':
        return {
          title: translate('pages.login.notInvitedTitle'),
          message: translate('pages.login.notInvitedDescription'),
        };
      default:
        return {
          title: translate('pages.login.defaultAuthFailTitle'),
          message: translate('pages.login.defaultAuthFailDescription'),
        };
    }
  };

  const loginFailContent = rejected && rejected !== 'unknown' ? getContentByStatus(rejected) : null;

  return (
    <Page title={translate('pages.login.signIn')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <HeaderStyle>
              <Logo sx={{ maxWidth: '400px' }} />
            </HeaderStyle>
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
              {hasCode ? (
                <Box sx={{ textAlign: 'center', my: 4 }}>
                  <CircularProgress sx={{ mb: 4 }} />

                  <p>{translate('pages.login.pleaseWait')}</p>
                </Box>
              ) : (
                <>
                  <Stack direction="row" sx={{ mb: 5, alignItems: 'center', textAlign: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      {loginFailContent && (
                        <ReportSharp
                          sx={{
                            color: theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.error.dark,
                            fontSize: '3rem',
                          }}
                        />
                      )}

                      <Typography variant="h3" sx={{ px: 5, mb: 5 }}>
                        {loginFailContent ? loginFailContent.title : translate('pages.login.welcomeBack')}
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                        {loginFailContent ? loginFailContent.message : translate('pages.login.signIn')}
                      </Typography>
                    </Box>
                  </Stack>
                  <LoginForm />
                </>
              )}
            </Paper>
            <FooterStyle>
              <Box sx={{ textAlign: 'center', pb: 3 }}>
                <Typography gutterBottom variant="caption">
                  {translate('generalLabels.poweredBy')}
                </Typography>
                <DiagridLogo sx={{ maxHeight: '40px' }} />
              </Box>
            </FooterStyle>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
