import { PropsWithChildren, useMemo } from 'react';
import { ThemeColorPresets, ThemeContrast, ThemeLocalization, ThemeRtlLayout } from '@diagrid/cloud-ui-shared/components';
import { alpha, useTheme } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import { useLocales } from 'src/hooks/useLocales';

export function ThemeSettings({ children }: PropsWithChildren) {
  const defaultTheme = useTheme();
  const { currentLang } = useLocales();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
        warning: {
          ...defaultTheme.palette.warning,
          dark: '#FFA600',
        },
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme]
  );

  return (
    <ThemeColorPresets themeOptions={themeOptions}>
      <ThemeContrast>
        <ThemeLocalization currentLang={currentLang}>
          <ThemeRtlLayout>{children}</ThemeRtlLayout>
        </ThemeLocalization>
      </ThemeContrast>
    </ThemeColorPresets>
  );
}
