import { SimplePaletteColorOptions } from '@mui/material';
import { palette } from './palette';

export const colorPresets = {
  // DEFAULT
  default: {
    name: 'default',
    ...(palette.light.primary as SimplePaletteColorOptions),
  },

  // CYAN
  cyan: {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  blue: {
    name: 'blue',
    lighter: '#D8F7FE',
    light: '#8BDAFC',
    main: '#3EA9F5',
    dark: '#1F63B0',
    darker: '#0B3175',
    contrastText: '#fff',
  },
  blueLightTheme: {
    name: 'blue-light-theme',
    lighter: '#3EA9F5', // 500
    light: '#2D84D2', // 600
    main: '#1F63B0', // 700
    dark: '#13468E', // 800
    darker: '#0B3175', // 900
    contrastText: '#fff',
  },
};

export const defaultPreset = colorPresets.blue;
export const cyanPreset = colorPresets.cyan;
export const bluePreset = colorPresets.blue;
export const blueLightPreset = colorPresets.blueLightTheme;

export function getColorPresets(presetsKey) {
  return {
    cyan: cyanPreset,
    blue: bluePreset,
    blueLightTheme: blueLightPreset,
    default: defaultPreset,
  }[presetsKey];
}
