type User = {
  email?: string;
  given_name?: string;
  family_name?: string;
};

export function parseIntakeRoute(route: string, user: User) {
  const userEmail = user?.email;
  const firstName = user?.given_name;
  const lastName = user?.family_name;
  let intakeRoute = route;

  if (userEmail) {
    intakeRoute += `?email=${userEmail}`;
  }

  if (firstName && firstName !== userEmail) {
    intakeRoute += `&firstname=${firstName}`;
  }

  if (lastName) {
    intakeRoute += `&lastname=${lastName}`;
  }

  return intakeRoute;
}
