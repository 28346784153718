import { IconButtonAnimate } from '@diagrid/cloud-ui-shared/components';
import { CheckCircleSharp, CloseSharp, InfoSharp, WarningSharp } from '@mui/icons-material';
import { Box, Collapse, GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import useSettings from '../hooks/useSettings';

export function NotistackProvider({ children }: PropsWithChildren) {
  const { themeDirection } = useSettings();
  const theme = useTheme();
  const notistackRef = useRef(null);

  const isLight = theme.palette.mode === 'light';
  const isRTL = themeDirection === 'rtl';

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <GlobalStyles
        styles={{
          '#root': {
            '& .notistack-MuiContent': {
              width: '100%',
              padding: theme.spacing(1),
              margin: theme.spacing(0.25, 0),
              boxShadow: theme.customShadows.z8,
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.grey[isLight ? 0 : 800],
              backgroundColor: theme.palette.grey[isLight ? 900 : 0],
              '&.notistack-MuiContent-success, &.notistack-MuiContent-error, &.notistack-MuiContent-warning, &.notistack-MuiContent-info': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
              [theme.breakpoints.up('md')]: {
                minWidth: 240,
              },
              '&:last-child': {
                paddingLeft: theme.spacing(1),
                marginRight: 0,
                color: theme.palette.action.active,
                '& svg': { width: 20, height: 20 },
              },
            },
            '& #notistack-snackbar': {
              padding: '0 !important',
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        }}
      />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={5000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={<InfoSharp width={24} height={24} color="info" />} color="info" />,
          success: <SnackbarIcon icon={<CheckCircleSharp width={24} height={24} color="success" />} color="success" />,
          warning: <SnackbarIcon icon={<WarningSharp width={24} height={24} color="warning" />} color="warning" />,
          error: <SnackbarIcon icon={<InfoSharp width={24} height={24} color="error" />} color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButtonAnimate size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <CloseSharp />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

type SnackbarIconProps = {
  icon: ReactNode;
  color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      {icon}
    </Box>
  );
}
