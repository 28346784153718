import { useCollapseDrawer } from '@diagrid/cloud-ui-shared/hooks';
import { Box, BoxProps, LinkBaseProps } from '@mui/material';
import { SxProps, useTheme, Theme } from '@mui/material/styles';
import { useMemo } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

type LogoProps = {
  disabledLink?: boolean;
  logoPaths: {
    light: string;
    lightSmall: string;
    dark: string;
    darkSmall: string;
    lightFree?: string;
    darkFree?: string;
  };
  switchInDarkMode?: boolean;
  alt: string;
  href: string;
  target?: string;
  external?: boolean;
  isFreePlan?: boolean;
  sx?: SxProps<Theme>;
};

export function Logo({
  disabledLink = false,
  switchInDarkMode = true,
  logoPaths,
  alt,
  href,
  target = '_self',
  external = false,
  isFreePlan = false,
  sx,
  ...props
}: LogoProps & Partial<BoxProps> & Partial<LinkBaseProps> & Partial<LinkProps>) {
  const theme = useTheme();
  const { isCollapse } = useCollapseDrawer();
  const isLight = theme.palette.mode === 'light';

  const logoPath = useMemo(() => {
    if (isLight && switchInDarkMode) {
      if (isFreePlan) {
        return isCollapse ? logoPaths.lightSmall : logoPaths.lightFree;
      }

      return isCollapse ? logoPaths.lightSmall : logoPaths.light;
    }

    if (isFreePlan) {
      return isCollapse ? logoPaths.darkSmall : logoPaths.darkFree;
    }

    return isCollapse ? logoPaths.darkSmall : logoPaths.dark;
  }, [isCollapse, isFreePlan, isLight, logoPaths, switchInDarkMode]);

  const logo = <Box component="img" alt={alt} src={logoPath} sx={{ width: '100%', height: 'auto', ...sx }} {...(props as BoxProps)} />;

  const routerLinkProps = { ...(props as LinkProps), target, to: href };

  if (disabledLink) {
    return logo;
  }

  // we want to open the link in a new tab, router link will route this to a local view regardless of the target
  if (target === '_blank') {
    return (
      <Link {...(props as LinkBaseProps)} href={href} target={target}>
        {logo}
      </Link>
    );
  }

  if (external) {
    return (
      <Link {...(props as LinkBaseProps)} href={href}>
        {logo}
      </Link>
    );
  }

  return <RouterLink {...routerLinkProps}>{logo}</RouterLink>;
}
