/* eslint-disable react/prop-types */
import { OrganizationContext, PRODUCT_PLANS } from '@diagrid/cloud-ui-shared';
import { ComponentType, useContext } from 'react';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';

const {
  mcp: { free },
} = PRODUCT_PLANS;
export function withRbac(ChildComponent: ComponentType) {
  return ({
    operation = undefined,
    ...props
  }: {
    operation?: {
      resource: string | string[];
      verb: string[];
      skipOrgCheck?: boolean;
      id?: string;
    };
    [x: string]: unknown;
  }) => {
    const { isAuthorized, isGlobalAdmin } = useContext(UsersRolesContext);
    const { organization, planName } = useContext(OrganizationContext);
    const isFreePlan = planName === free;

    if (organization?.blocked && !operation?.skipOrgCheck) {
      return null;
    }

    if (isGlobalAdmin && !isFreePlan) {
      return <ChildComponent {...props} />;
    }

    if (isAuthorized(operation)) {
      return <ChildComponent {...props} />;
    }

    return null;
  };
}
