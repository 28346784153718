import { Box, Button, Stack } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { BlockContent, MultiFilePreview, RejectionFiles } from '.';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  border: `1px dashed ${theme.palette.grey['500_32']}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

export type UploadMultiFileProps = {
  files: FieldValues['files'];
  error?: boolean;
  showPreview?: boolean;
  onUpload?: () => void;
  onRemove?: () => void;
  onRemoveAll?: () => void;
  helperText?: ReactNode;
  sx?: SxProps<Theme>;
  [key: string]: unknown;
};

export function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFilePreview files={files} showPreview={showPreview} onRemove={onRemove} />

      {files.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained" onClick={onUpload}>
            Upload files
          </Button>
        </Stack>
      )}

      {helperText && helperText}
    </Box>
  );
}
