import i18next from 'i18next';
import { TourToolTipContainer } from '../TourToolTipContainer';
import { TOUR_SETTINGS } from '../constants';
import { calculatePosition } from './utils';

export const setClusterStep = (step, navigate, setStep, setTourState, submitCompletedUITours) => {
  switch (step) {
    case 0:
      navigate('/clusters', { replace: true });
      setStep(step);
      break;
    case 1:
      navigate('/clusters/create', { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 400);
      break;
    case 9:
      navigate('/clusters/create', { replace: true });
      setTourState({
        isShowClusterStatusMock: false,
      });
      setStep(step);
      break;
    case 10:
      navigate('/clusters/create', { replace: true });
      submitCompletedUITours(TOUR_SETTINGS.CLUSTER_FINISHED);
      setTourState({
        isShowClusterStatusMock: true,
      });
      setTimeout(() => {
        setStep(step);
      }, 400);
      break;
    default:
      setStep(step);
  }
};

export const clusterSteps = [
  {
    selector: '[data-tour="create-cluster-button"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.welcome.title')}>
        {i18next.t('tour.clusterScenario.welcome.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="connection-name-input"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.connectionName.title')}>
        {i18next.t('tour.clusterScenario.connectionName.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="k-distro-select"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.distribution.title')}>
        {i18next.t('tour.clusterScenario.distribution.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="install-dapr-checkbox"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.installDapr.title')}>
        {i18next.t('tour.clusterScenario.installDapr.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="auto-sync-dapr-checkbox"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.syncDaprConfig.title')}>
        {i18next.t('tour.clusterScenario.syncDaprConfig.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="rollout-all-apps-checkbox"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.rolloutAllApps.title')}>
        {i18next.t('tour.clusterScenario.rolloutAllApps.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="helm-arguments"]',
    position: (props) => calculatePosition(props, 'bottom'),
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.helmArguments.title')}>
        {i18next.t('tour.clusterScenario.helmArguments.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="cert-rotation-checkbox"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.certRotation.title')}>
        {i18next.t('tour.clusterScenario.certRotation.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="agent-upgrade-checkbox"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.agentUpgrade.title')}>
        {i18next.t('tour.clusterScenario.agentUpgrade.content')}
      </TourToolTipContainer>
    ),
  },

  {
    selector: '[data-tour="create-cluster-confirm-button"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.createCluster.title')}>
        {i18next.t('tour.clusterScenario.createCluster.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="install-conductor-agent-view"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.clusterScenario.installAgent.title')}>
        {i18next.t('tour.clusterScenario.installAgent.content')}
      </TourToolTipContainer>
    ),
  },
];
