import { useTheme } from '@mui/material';
import { components } from '@reactour/tour';
import { PropsWithChildren } from 'react';

export function TourBadge({ children }: PropsWithChildren) {
  const theme = useTheme();
  return (
    <components.Badge styles={{ badge: (base) => ({ ...base, backgroundColor: theme.palette.primary.main }) }}>{children}</components.Badge>
  );
}
