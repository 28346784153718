import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ThemeProvider } from '@diagrid/cloud-ui-shared/components';
import { API_REQUESTS_HEADERS, ERROR_CODES, parseIntakeRoute, shouldNavigateToUserSurvey } from '@diagrid/cloud-ui-shared/utils';
import { capitalize } from 'lodash';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH0_API, PATH_AFTER_LOGIN } from 'src/config';
import { useLocales } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { PATH_API, PATH_PAGE } from 'src/routes/paths';
import { ThemeSettings, customShadows, palette, shadows, typography } from 'src/theme';
import { security } from 'src/utils';
import { jsonApiClient } from 'src/utils/axios';
import { API_REQUESTS, CUSTOM_CLAIMS, DIAGRID_ROLES, DIAGRID_ROLES_ORG_ADMIN } from 'src/utils/constants';
import { LoadingScreen } from '../LoadingScreen';

type Auth0ProviderWithRedirectCallbackProps = {
  children: ReactNode;
  domain: string;
  clientId: string;
};

const Auth0ProviderWithRedirectCallback = ({
  children,
  domain,
  clientId,
  ...props
}: Auth0ProviderWithRedirectCallbackProps & Auth0ProviderOptions): JSX.Element => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [isCallbackLoading, setIsCallbackLoading] = useState(false);
  const { themeMode, themeDirection } = useSettings();

  function handleCallbackError(error) {
    const errorHeader = error?.response?.headers[API_REQUESTS_HEADERS.errorCodeHeader];
    const isNoAccess = errorHeader === ERROR_CODES.noProductAccess;
    const customErrorDesc = error?.response?.headers[API_REQUESTS_HEADERS.errorDescHeader];
    const customOrGenericErrorDesc = customErrorDesc ?? translate('pages.500.description');
    let errorDesc = isNoAccess ? translate('pages.error.noProductAccess') : customOrGenericErrorDesc;
    if (error.message && !customErrorDesc) {
      errorDesc = error.message;
    }
    errorDesc = capitalize(errorDesc);
    security.logout({
      clientId: AUTH0_API.clientId,
      logoutParams: {
        returnTo: `${window.location.origin}/error?error=${
          isNoAccess ? encodeURIComponent('access_denied') : error.name
        }&error_description=${encodeURIComponent(errorDesc)}`,
        federated: true,
      },
    });
  }

  const onRedirectCallback = async (appState, user) => {
    const returnTo = appState?.returnTo !== '/' ? appState?.returnTo : PATH_AFTER_LOGIN;

    setIsCallbackLoading(true);
    if (user) {
      try {
        // can't use the redux hook because we're outside the provider
        const cbResp = await jsonApiClient.post(PATH_API.callback);
        if (cbResp.status === 201 || cbResp.status === 205) {
          if (cbResp.status === 205) {
            if (user[CUSTOM_CLAIMS.ssoUser]) {
              // sso users will get the token exchanged correctly
              security.loginWithRedirect();
              return;
            }

            if (user && !user?.email_verified) {
              // regular users have to verify email first
              security.logout({
                clientId: AUTH0_API.clientId,
                logoutParams: {
                  returnTo: `${window.location.origin}/sign-up-success`,
                  federated: true,
                },
              });
              return;
            }
          }

          // org created, force token refresh, required because on first login, some claims can be added to the user after this callback
          await security.getAccessTokenSilently()({
            cacheMode: 'off',
          });
        } else if (cbResp.status === 204) {
          if (user && !user?.email_verified) {
            // use the cached token here otherwise we'll get stuck in a loop of trying to authenticate
            const tokenWithoutOrgIdClaims = await security.getAccessTokenSilently()();
            security.logout({
              clientId: AUTH0_API.clientId,
              logoutParams: {
                returnTo: `${window.location.origin}/verify-email?token=${tokenWithoutOrgIdClaims}`,
                federated: true,
              },
            });
            return;
          }
        }
      } catch (error) {
        console.error('Error on callback', error);
        if (user && !user?.email_verified) {
          security.logout({
            clientId: AUTH0_API.clientId,
            logoutParams: {
              returnTo: `${window.location.origin}/verify-email`,
              federated: true,
            },
          });
          return;
        }

        handleCallbackError(error);
        return;
      }
    }

    try {
      // can't use the redux hook because we're outside the provider
      const orgCBResp = await jsonApiClient.get(PATH_API.userOrganizations);
      const userOrgs = orgCBResp.data.data;
      const mcpOrgs = userOrgs?.filter(({ attributes }) => attributes.products.mcp.enabled);
      const mcpDefaultOrgExists = mcpOrgs?.find((o) => o.id === user[CUSTOM_CLAIMS.defaultOrganization]);
      const intakeRoute = parseIntakeRoute(PATH_PAGE.userIntakeSurvey, user);
      let diagridUser = { data: { data: { attributes: { roles: [] } } } };
      let userRoles = [];

      if (mcpDefaultOrgExists) {
        const mcpDefaultOrgAttributes = mcpDefaultOrgExists?.attributes;
        localStorage.setItem(`${user?.sub}/currentOrg`, JSON.stringify(user[CUSTOM_CLAIMS.defaultOrganization]));
        diagridUser = await jsonApiClient({
          url: PATH_API.userSelf,
          method: 'GET',
          headers: {
            [API_REQUESTS.orgIdHeader]: user[CUSTOM_CLAIMS.defaultOrganization],
          },
        });
        userRoles = diagridUser?.data?.data?.attributes?.roles;

        setIsCallbackLoading(false);

        if (
          (userRoles?.includes(DIAGRID_ROLES.admin.id) || userRoles?.includes(DIAGRID_ROLES_ORG_ADMIN)) &&
          shouldNavigateToUserSurvey({ organization: mcpDefaultOrgAttributes, userEmail: user?.email })
        ) {
          navigate(intakeRoute, { replace: true });
          return;
        }

        navigate(returnTo, { replace: true });
        return;
      }

      // cant find the currentOrgId in the mcpOrgs
      if (mcpOrgs.length > 0) {
        // the currentOrgId does not exist in this user, maybe because it's an org from another env or conductor
        // Always use the first org
        try {
          console.info('Auth0ProviderWithRedirectCallback: default org does not exist, setting first org as current org');
          const mcpFirstDefaultOrg = mcpOrgs[0];
          localStorage.setItem(`${user?.sub}/currentOrg`, JSON.stringify(mcpFirstDefaultOrg.id));
          // can't use the redux hook because we're outside the provider
          await jsonApiClient.put(`${PATH_API.userOrganizations}/${mcpFirstDefaultOrg.id}`);
          diagridUser = await jsonApiClient({
            url: PATH_API.userSelf,
            method: 'GET',
            headers: {
              [API_REQUESTS.orgIdHeader]: mcpFirstDefaultOrg.id,
            },
          });
          userRoles = diagridUser?.data?.data?.attributes?.roles;
          setIsCallbackLoading(false);

          if (
            (userRoles?.includes(DIAGRID_ROLES.admin.id) || userRoles?.includes(DIAGRID_ROLES_ORG_ADMIN)) &&
            shouldNavigateToUserSurvey({ organization: mcpFirstDefaultOrg.attributes, userEmail: user?.email })
          ) {
            navigate(intakeRoute, { replace: true });
            return;
          }

          navigate(returnTo, { replace: true });
        } catch (error) {
          handleCallbackError(error);
        }
      }
    } catch (error) {
      handleCallbackError(error);
    }
  };

  return (
    <ThemeProvider
      typography={typography}
      customShadows={customShadows}
      shadows={shadows}
      palette={palette}
      themeMode={themeMode}
      themeDirection={themeDirection}
    >
      <ThemeSettings>
        <Auth0Provider domain={domain} clientId={clientId} onRedirectCallback={onRedirectCallback} {...props}>
          {isCallbackLoading ? <LoadingScreen isDashboard={false} /> : children}
        </Auth0Provider>
      </ThemeSettings>
    </ThemeProvider>
  );
};

export { Auth0ProviderWithRedirectCallback };
