export const getThemeMode = (): {
  themeMode: 'light' | 'dark';
  localThemeMode: string;
} => {
  const themeMode = getDefaultThemeMode();
  const getLocalThemeMode = () => {
    const localData = localStorage.getItem('settings') || '';
    if (localData) {
      return JSON.parse(localData)?.themeMode ?? themeMode;
    }
    return themeMode;
  };

  return {
    themeMode,
    localThemeMode: getLocalThemeMode(),
  };
};

function getDefaultThemeMode(): 'light' | 'dark' {
  if (process.env.NODE_ENV === 'test') {
    return 'dark';
  }
  const isLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;
  return isLightMode ? 'light' : 'dark';
}
