import { datadogRum } from '@datadog/browser-rum';
import { initializeClient } from '@diagrid/cloud-ui-shared/redux/client';
import { isEmpty } from 'lodash';
import { HOST_API } from '../config';
import { security } from './auth';
import { API_REQUESTS, CUSTOM_CLAIMS } from './constants';

const baseURL = HOST_API ?? '/'; // localhost
const jsonApiClient = initializeClient({ baseURL });

async function setAuth0Headers(request) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const user: any = await security.getAuth0User();
    const accessToken = await security.getAccessTokenSilently()();

    if (user?.sub) {
      let currentOrgId = localStorage.getItem(`${user.sub}/currentOrg`);
      if (currentOrgId) {
        // our local storage utility stores values with JSON.stringify
        currentOrgId = JSON.parse(currentOrgId);
      } else {
        currentOrgId = user[CUSTOM_CLAIMS.defaultOrganization];
      }
      request.headers[API_REQUESTS.orgIdHeader] = currentOrgId;
    }

    if (!import.meta.env.DEV && !isEmpty(user)) {
      try {
        datadogRum.setUser({
          id: user.sub,
          name: user.name,
          email: user.email,
          organization: user[CUSTOM_CLAIMS.defaultOrganization],
        });
      } catch (err) {
        console.error(err);
      }
    }

    request.headers.Authorization = `bearer ${accessToken}`;
  } catch (err) {
    console.error(err);
    throw err;
  }
  return request;
}

jsonApiClient.interceptors.request.use(setAuth0Headers, (error) => error);

export { jsonApiClient };
