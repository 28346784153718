import { PropsWithChildren } from 'react';
import { ToursStateProvider } from '../../contexts/ToursStateContext';
import { TourProviderWrapper } from './TourProviderWrapper';

export function AllToursProviders({ children }: PropsWithChildren) {
  return (
    <ToursStateProvider>
      <TourProviderWrapper>{children}</TourProviderWrapper>
    </ToursStateProvider>
  );
}
