import { generatei18nConfig } from '@diagrid/cloud-ui-shared/utils';
import i18n from 'i18next';
import { defaultLang } from '../config';
import enLocales from './en.json';
import esLocals from './es.json';

generatei18nConfig({
  i18n, // i18next instance
  defaultLang,
  locales: {
    en: { translations: enLocales },
    es: { translations: esLocals },
  },
});
