import { initializeApiStore } from '@diagrid/cloud-ui-shared/redux/client';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import type { Action, Reducer } from 'redux';
import type { PersistConfig, PersistState } from 'redux-persist';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { HOST_API } from 'src/config';
import { PATH_API } from 'src/routes/paths';
import { TYPE_REF } from 'src/utils/constants';
import { jsonApiClient } from '../utils/axios';
import chartsReducer from './slices/apexCharts';

declare module 'redux-persist' {
  export function persistReducer<S, A extends Action = Action, P = S>(
    config: PersistConfig<S>,
    baseReducer: Reducer<S, A, P>
  ): Reducer<S & { _persist: PersistState }, A, P & { _persist?: PersistState }>;
}

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const apiStore = initializeApiStore({
  basePath: HOST_API ? `${HOST_API}${PATH_API.root}` : PATH_API.root,
  jsonApiClient,
  typeReference: TYPE_REF,
  reducerPath: 'diagrid.io/v1beta1',
});

export const {
  useCreateMutation,
  useDestroyManyMutation,
  useDestroyMutation,
  useFindQuery,
  useGetVersionsQuery,
  useLazyFindQuery,
  usePatchMutation,
  useUpdateMutation,
} = apiStore;

const rootReducer = combineReducers({
  [apiStore.reducerPath]: apiStore.reducer,
  apexCharts: chartsReducer,
});

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(apiStore.middleware),
});

setupListeners(store.dispatch);

const persistor = persistStore(store);

const { dispatch } = store;

export { dispatch, persistor, rootPersistConfig, rootReducer, store, useDispatch, useSelector };
