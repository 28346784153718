import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Grid2 as Grid } from '@mui/material';
import { useLocales } from 'src/hooks/useLocales';
import { RHFFormProvider } from '../../components/hook-form';

export function LoginForm() {
  const { translate } = useLocales();
  const { loginWithRedirect, logout, error: rejected, isLoading } = useAuth0();

  const onSubmit = async (e) => {
    // DO NOT REMOVE - this is needed to prevent the form from submitting in safari which causes a page reload
    e.preventDefault();
    e.stopPropagation();

    try {
      if (rejected) {
        localStorage.clear();
        await logout();
      } else {
        await loginWithRedirect();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <RHFFormProvider onSubmit={onSubmit} methods={{} as any}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid size={12}>
          <LoadingButton loading={isLoading} type="submit" variant="contained" fullWidth size="large" color="primary">
            {rejected && rejected.message !== 'unknown' ? translate('generalLabels.tryAgain') : translate('pages.login.signInButton')}
          </LoadingButton>
        </Grid>
      </Grid>
    </RHFFormProvider>
  );
}
