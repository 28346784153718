import { getFormattedDateFromUTC, getUTCFromLocalDate } from '../utils';
import { format, formatDistanceToNow, formatDistanceToNowStrict, fromUnixTime, getTime } from 'date-fns';
import i18next from 'i18next';

export function getLocale(allLangs, defaultLang) {
  const selectedLang = i18next.resolvedLanguage;
  const currentLang = allLangs.find((_lang) => _lang.value === selectedLang) || defaultLang;

  return currentLang && currentLang.dateFNSLocale;
}

export function formatDate(date, locale) {
  return format(new Date(date), 'MMM dd, yyyy', { locale });
}

export function formatDateTime(date, locale) {
  return format(new Date(date), 'MMM dd, yyyy hh:mm:ss a', { locale });
}

export function formatShortDateTime(date, locale) {
  return format(new Date(date), 'MMM dd hh:mm:ss a', { locale });
}

export function formatTime(date, locale) {
  return format(new Date(date), 'hh:mm:ss a', { locale });
}

export function formatTimestamp(date) {
  return getTime(new Date(date));
}

export function formatDateTimeSuffix(date, locale) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm a', { locale });
}

export function formatToNow(date, locale) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale,
  });
}

export function formatToNowStrict(date, addSuffix, { ...other }) {
  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: addSuffix ?? true,
    ...other,
  });
}

export function formatTimeSince(date) {
  let timeSince = formatToNowStrict(date, false, { unit: 'hour' });

  if (timeSince === '0 hours') {
    timeSince = formatToNowStrict(date, false, { unit: 'minute' });
  }
  return timeSince;
}

export function formatGetTimeSince(values) {
  const out: {
    dateVal: string;
    stringVal: string;
    dateString?: string;
  } = { dateVal: i18next.t('generalLabels.unknown'), stringVal: i18next.t('generalLabels.unknown'), dateString: undefined };
  const [epoch, diff] = values ?? [];
  if (epoch && diff) {
    // get the actual unix time by subtracting time since [1] from unix time now [0] and format into real date
    const unixDate = fromUnixTime(epoch - parseFloat(diff));
    const date = unixDate.toISOString();

    out.stringVal = formatTimeSince(date);
    out.dateString = getFormattedDateFromUTC(getUTCFromLocalDate(unixDate) ?? '');
  }

  return out;
}

export function getRelativeTime(relativeTime: string): {
  startTime: Date;
  endTime: Date;
} {
  const now = Date.now();
  const oneMinute = 60 * 1000;
  const oneHour = 60 * 60 * 1000;
  const oneDay = 24 * 60 * 60 * 1000;

  const defaultRange = { startTime: new Date(), endTime: new Date() };

  if (!relativeTime) return defaultRange;
  if (relativeTime.endsWith('m') || relativeTime.endsWith('h') || relativeTime.endsWith('d')) {
    const unit = relativeTime[relativeTime.length - 1];
    const unitMap = {
      m: oneMinute,
      h: oneHour,
      d: oneDay,
    };
    return { startTime: new Date(now - parseInt(relativeTime) * unitMap[unit]), endTime: new Date(now) };
  }
  return defaultRange;
}
