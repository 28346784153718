// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  about: '/about-us',
  buildInfo: '/build-info',
  comingSoon: '/coming-soon',
  components: '/components',
  contact: '/contact-us',
  faqs: '/faqs',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  payment: '/payment',
  pricing: '/pricing',
  userIntakeSurvey: '/new-user-survey',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    clusters: path(ROOTS_DASHBOARD, '/clusters'),
  },
  apiKeys: {
    index: path(ROOTS_DASHBOARD, '/api-keys'),
    new: path(ROOTS_DASHBOARD, '/api-keys/create'),
  },
  clusters: {
    new: path(ROOTS_DASHBOARD, '/clusters/create'),
    edit: path(ROOTS_DASHBOARD, '/clusters/:id/edit'),
    editDapr: path(ROOTS_DASHBOARD, '/clusters/:id/edit/dapr'),
    detail: path(ROOTS_DASHBOARD, '/clusters/:id/detail/:tab'),
    advisories: path(ROOTS_DASHBOARD, '/clusters/:id/detail/advisor'),
    health: path(ROOTS_DASHBOARD, '/clusters/:id/health'),
  },
  clusterApps: {
    list: path(ROOTS_DASHBOARD, '/clusters/:clusterId/detail/apps'),
    detail: path(ROOTS_DASHBOARD, '/clusters/:clusterId/app/:appId/detail/:tab'),
    summaryDetail: path(ROOTS_DASHBOARD, '/clusters/:clusterId/app/:appId/detail/summary/:subTab'),
  },
  notifications: {
    management: path(ROOTS_DASHBOARD, '/notifications'),
    newNotification: path(ROOTS_DASHBOARD, '/notifications/create'),
    newAlert: path(ROOTS_DASHBOARD, '/alerts/create'),
    events: path(ROOTS_DASHBOARD, '/notifications/events'),
    rules: path(ROOTS_DASHBOARD, '/notifications/rules'),
    channels: path(ROOTS_DASHBOARD, '/notifications/channels'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    new: path(ROOTS_DASHBOARD, '/users/create'),
    account: path(ROOTS_DASHBOARD, '/users/settings/general'),
    edit: path(ROOTS_DASHBOARD, '/users/:id/edit'),
  },
  organization: {
    root: path(ROOTS_DASHBOARD, '/organization/'),
    summary: path(ROOTS_DASHBOARD, '/organization/summary'),
    newSSOConnection: path(ROOTS_DASHBOARD, '/organization/ssoconnections'),
    audit: path(ROOTS_DASHBOARD, '/organization/audit'),
    ssoList: path(ROOTS_DASHBOARD, '/organization/sso'),
    edit: path(ROOTS_DASHBOARD, '/organization/edit'), // only one org right now no need for id
  },
  configurator: {
    root: path(ROOTS_DASHBOARD, '/component-builder'),
  },
  resiliencyBuilder: {
    root: path(ROOTS_DASHBOARD, '/resiliency-builder'),
  },
};

export const PATH_DOCS = '#';

// ----------------------------------------------------------------------

const API_ROOT = '/apis';
const CRA_DIAGRID_V1BETA1_API = '/cra.diagrid.io/v1beta1';
const DIAGRID_V1BETA1_API = '/diagrid.io/v1beta1';

export const PATH_API = {
  root: API_ROOT,
  mcp: path(API_ROOT, '/diagrid.io/v1beta1'),
  craDiagridV1Beta1: CRA_DIAGRID_V1BETA1_API,
  conductorV1Beta1: DIAGRID_V1BETA1_API,
  accounts: path(API_ROOT, '/diagrid.io/v1beta1/accounts'),
  workloads: path(API_ROOT, '/diagrid.io/v1beta1/workloads'),
  callback: path(API_ROOT, '/diagrid.io/v1beta1/user/login_callback?product=mcp'),
  userOrganizations: path(API_ROOT, '/diagrid.io/v1beta1/user/organizations'),
  userSelf: path(API_ROOT, '/diagrid.io/v1beta1/user'),
  resendVerification: path(API_ROOT, '/diagrid.io/v1beta1/user/verify_email'),
};
