import { Logo } from '@diagrid/cloud-ui-shared/components';
import { SxProps, Theme } from '@mui/material';
import { LINKS } from 'src/utils/external-links';

type DiagridLogoProps = {
  disabledLink?: boolean;
  sx?: SxProps<Theme>;
};

export function DiagridLogo({ disabledLink = false, sx }: DiagridLogoProps) {
  const logoPaths = {
    light: `${window.location.origin}/logo/Diagrid_horiz.svg`,
    lightSmall: `${window.location.origin}/logo/d.svg`,
    dark: `${window.location.origin}/logo/Diagrid_negative_horiz.svg`,
    darkSmall: `${window.location.origin}/logo/d_negative.svg`,
  };

  return (
    <Logo disabledLink={disabledLink} logoPaths={logoPaths} alt="Diagrid Company Logo" href={LINKS.marketing} sx={sx} target="_blank" />
  );
}
