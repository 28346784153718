import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { API_METHODS, PRODUCT_PLANS, handleError, useLocalStorage } from '@diagrid/cloud-ui-shared';
import { Card, CircularProgress, Container, Paper, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/components/Page';
import { PATH_AFTER_LOGIN } from 'src/config';
import { useLocales } from 'src/hooks/useLocales';
import { useFindQuery, useUpdateMutation } from 'src/redux/store';
import { CUSTOM_CLAIMS } from 'src/utils/constants';

export function NewUserIntake() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [updateCurrentOrganization] = useUpdateMutation();
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { data: orgs, isLoading: isLoadingUserOrganizations } = useFindQuery(
    { type: 'userOrganizations', normalization: { raw: true } },
    { skip: !user || !isAuthenticated }
  );
  const [localOrgId] = useLocalStorage(`${user?.sub}/currentOrg`);

  const [formId, setFormId] = useState(null);

  const currentOrgId = localOrgId ?? user?.[CUSTOM_CLAIMS.defaultOrganization];
  const currentOrg = useMemo(() => orgs?.data?.find((o) => o.id === currentOrgId) ?? {}, [currentOrgId, orgs]);
  const planName = currentOrg?.products?.mcp?.plan;

  const showSpinner = isLoadingUserOrganizations && !user;

  useEffect(() => {
    if (isEmpty(currentOrg) && !isLoadingUserOrganizations && !planName && !user) {
      const error = new Error(
        'No current organization or user, unable to complete user intake survey. User likely not logged in anymore or refreshed the page. Redirecting to login page.'
      );
      error.name = 'UserIntakeSurveyError';

      datadogRum.addError(error);

      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    }
  });

  useEffect(() => {
    switch (planName) {
      case PRODUCT_PLANS.mcp.enterprise:
        setFormId('46d02f94-9bc2-406b-b488-a13484d82914');
        break;
      case PRODUCT_PLANS.mcp.trial:
        setFormId('1976a117-8927-47d5-9ddf-93ba2c441732');
        break;
      case PRODUCT_PLANS.mcp.free:
        setFormId('13e2760c-095f-479c-8b30-2dfd21e3baf5');
        break;
      default:
        setFormId('13e2760c-095f-479c-8b30-2dfd21e3baf5');
        break;
    }
  }, [planName]);

  useEffect(() => {
    function initialize() {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script?.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '22146261',
            formId,
            target: '#hubspotForm',
            onFormReady() {
              const emailInput = document.getElementById(`email-${formId}`) as HTMLInputElement;
              if (emailInput) {
                emailInput.readOnly = true;
              }
            },
            async onFormSubmitted() {
              try {
                await updateCurrentOrganization({
                  type: 'organizations',
                  id: currentOrg.id,
                  data: { body: { name: currentOrg?.name, defaults: { inTakeSurveyCompleted: true } } },
                  method: API_METHODS.POST,
                });
                setTimeout(() => {
                  navigate(PATH_AFTER_LOGIN, { replace: true });
                }, 2000);
              } catch (error) {
                handleError({
                  error,
                  enqueueSnackbar,
                  overrideFallbackErrorMessage: translate('notifications.errors.userIntakeSurvey.submit'),
                });
              }
            },
          });
        }
      });
    }

    if (currentOrg?.id && formId) initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, formId]);

  return (
    <Page title={translate('pages.userIntakeSurvey.pageTitle')}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Paper elevation={3} sx={{ mt: 3 }}>
            {showSpinner ? (
              <Card sx={{ padding: 4 }}>
                <CircularProgress />
              </Card>
            ) : (
              <StyledFormContainer id="hubspotForm" />
            )}
          </Paper>
        </ContentStyle>
      </Container>
    </Page>
  );
}

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  margin: 'auto',
}));

const StyledFormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  textAlign: 'left',
  padding: '2rem !important',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.875rem',
  '*': { fontFamily: theme.typography.fontFamily },

  '& h2, h3': {
    backgroundColor: 'transparent !important',
    fontSize: '18px !important',
  },
  'input, textarea, select': {
    maxWidth: '30rem',
    color: 'currentColor',
    boxSizing: 'border-box',
    background: 'none',
    height: '3.5rem',
    margin: '0',
    display: 'block',
    minWidth: 0,
    width: ' 100%',
    padding: '16.5px 14px',
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: '1rem',
    '&:hover': {
      borderColor: theme.palette.grey[500],
      outline: `1px solid ${theme.palette.grey[500]}`,
    },
  },
  textarea: {
    minHeight: '10rem',
    maxWidth: '40rem',
  },
  'input[type="checkbox"]': {
    appearance: 'none',
    /* For iOS < 15 */
    backgroundColor: 'transparent',
    /* Not removed via appearance */
    margin: 0,

    font: 'inherit',
    color: 'currentColor',
    width: '2.4rem',
    height: '2.4rem',
    border: `2px solid ${theme.palette.grey[700]}`,
    borderRadius: '0.5rem',
    transform: 'scale(0.8)',
    display: 'grid',
    placeContent: 'center',
  },

  'input[type="checkbox"]::before': {
    content: "''",
    width: '1rem',
    height: '1rem',
    backgroundColor: '#fff',
    clipPath: 'polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)',
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
    transition: '100ms transform ease-in-out',
    boxShadow: ' inset 1em 1em var(--form-control-color)',
  },
  'input[type="checkbox"]:checked': {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  'input[type="checkbox"]:checked::before': {
    transform: 'scale(1.1)',
  },

  '.hs-form-checkbox': {
    margin: theme.spacing(2, 0),
    label: {
      display: 'inline-flex',
      alignItems: 'center',
      gridGap: theme.spacing(2),
    },
  },

  label: {
    marginBottom: theme.spacing(0.5),
    fontSize: '1rem',
    display: 'block',
    fontWeight: 700,
  },
  '.hs-main-font-element': {
    marginBottom: theme.spacing(4),
  },
  '.hs-form-required': {
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(0.5),
  },
  '.inputs-list': {
    'label.hs-error-msg': {
      color: theme.palette.error.main,
      paddingTop: '0.2rem',
    },
  },
  '.hs-form-field': {
    display: 'block',
    marginTop: theme.spacing(3),
  },
  'input[type="submit"]': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    outline: 0,
    border: 0,
    margin: 0,
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 700,
    lineHeight: 1.7142857142857142,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    minWidth: '4rem',
    padding: '6px 1rem',
    borderRadius: '8px',
    height: 'auto',
    width: 'auto',
    color: ' #fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 3px 1px -2px rgba(18, 28, 41, 0.2),0px 2px 2px 0px rgba(18, 28, 41, 0.14),0px 1px 5px 0px rgba(18, 28, 41, 0.12)',
    marginTop: theme.spacing(4),
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
