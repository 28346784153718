import { useState, useEffect } from 'react';

type HookWindowFocusProps = {
  isFocused: boolean;
  conditionalPolling: (x: number) => number | undefined;
};

const hasFocus = (): boolean => typeof document !== 'undefined' && document.hasFocus();

function useWindowFocus(): HookWindowFocusProps {
  const [focused, setFocused] = useState(hasFocus);

  useEffect(() => {
    setFocused(hasFocus());

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const conditionalPolling = (pollingTime: number) => {
    if (window.Cypress) {
      // we don't care about focus here
      return 5000;
    }

    return focused ? pollingTime : undefined;
  };

  return { isFocused: focused, conditionalPolling };
}

export { useWindowFocus };
