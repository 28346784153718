import AceEditor, { IAceEditorProps } from 'react-ace';
import { config } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/ext-language_tools';

import { FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import yamlWorkerUlr from 'ace-builds/src-noconflict/worker-yaml';

config.setModuleUrl('ace/mode/yaml_worker', yamlWorkerUlr);

type RHFAceEditorProps = {
  name: string;
} & IAceEditorProps;

export function RHFAceEditor({ name, ...other }: RHFAceEditorProps) {
  const { control } = useFormContext();
  const theme = useTheme();
  const isDarkTheme = useMemo(() => theme?.palette?.mode === 'dark', [theme?.palette?.mode]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <AceEditor
            width="100%"
            value={field.value}
            onChange={field.onChange}
            theme={isDarkTheme ? 'solarized_dark' : 'solarized_light'}
            mode="yaml"
            name={name}
            editorProps={{ $blockScrolling: true }}
            fontSize={14}
            wrapEnabled
            {...other}
          />
          {!!error && <FormHelperText sx={{ mb: 1, color: theme.palette.error.main }}>{error?.message}</FormHelperText>}
        </>
      )}
    />
  );
}
