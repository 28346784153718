import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { authz } from '@diagrid/shared-js';
import { differenceInHours } from 'date-fns';
import { isEmpty, merge } from 'lodash';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { PATH_API } from 'src/routes/paths';
import { TIMEOUTS } from 'src/utils/constants';
import { jsonApiClient } from '../utils/axios';
import { UsersRolesContext } from './UsersRolesContext';

const initialState = {
  manifestToken: null,
  tokens: null,
};

const rbacOperation = {
  resource: authz.model.resources.ephemeralApikeys,
  verb: authz.model.verbs.create,
};

const TokensContext = createContext({
  ...initialState,
  cleanup: () => null,
  renewManifestToken: () => null,
  tokens: null,
});

function TokensProvider({ children }: PropsWithChildren<unknown>) {
  const { user } = useAuth0();
  const { isAuthorized } = useContext(UsersRolesContext);
  const [tokens, setTokens] = useLocalStorage('tokens', { ...initialState });

  const [loadingTokens, setLoadingTokens] = useState(false);

  const initializeManifestToken = useCallback(async () => {
    try {
      setLoadingTokens(true);
      const { data } = await jsonApiClient.get(`${PATH_API.mcp}/apikeys/ephemeral?duration=86400&scope=read:manifest`);
      const neuData = merge(
        { ...tokens },
        {
          manifestToken: {
            expiry: new Date().getTime(),
            token: data?.token,
          },
        }
      );

      setTokens(neuData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTokens(false);
    }
  }, [setTokens, tokens]);

  useEffect(() => {
    if (!user || !user?.sub || !isAuthorized(rbacOperation)) {
      return;
    }
    if (!loadingTokens) {
      if (isEmpty(tokens?.manifestToken)) {
        initializeManifestToken();
      } else {
        const { manifestToken } = tokens;
        const diff = differenceInHours(new Date().getTime(), manifestToken.expiry);

        if (diff >= TIMEOUTS.DEFAULT_MANIFEST_REFRESH_IN_HOURS) {
          initializeManifestToken();
        }
      }
    }
  }, [initializeManifestToken, isAuthorized, loadingTokens, tokens, user]);

  const cleanup = () => {
    setTokens(null);
  };

  return (
    <TokensContext.Provider
      value={{
        ...tokens,
        tokens,
        cleanup,
        renewManifestToken: initializeManifestToken,
        method: 'tokens',
      }}
    >
      {children}
    </TokensContext.Provider>
  );
}

export { TokensContext, TokensProvider };
