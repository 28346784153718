let getAccessTokenSilently = (_args?) => undefined;
let getUserFromAuth0 = null;

export const security = {
  logout: (_args?) => undefined,
  loginWithRedirect: (_args?) => undefined,
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func?) => (getAccessTokenSilently = func),
  getAuth0User: () => getUserFromAuth0,
  setAuth0User: (user) => (getUserFromAuth0 = user),
};
