import {
  formatDate,
  formatDateTime,
  formatDateTimeSuffix,
  formatGetTimeSince,
  formatShortDateTime,
  formatTime,
  formatTimeSince,
  formatTimestamp,
  formatToNow,
  formatToNowStrict,
  getLocale,
} from '@diagrid/cloud-ui-shared/utils';
import { allLangs, defaultLang } from 'src/config';

const locale = getLocale(allLangs, defaultLang);

export function fDate(date) {
  return formatDate(date, locale);
}

export function fDateTime(date) {
  return formatDateTime(date, locale);
}

export function fShortDateTime(date) {
  return formatShortDateTime(date, locale);
}

export function fTime(date) {
  return formatTime(date, locale);
}

export function fTimestamp(date) {
  return formatTimestamp(date);
}

export function fDateTimeSuffix(date) {
  return formatDateTimeSuffix(date, locale);
}

export function fToNow(date) {
  return formatToNow(date, locale);
}

export function fToNowStrict(date, addSuffix, { ...other }) {
  return formatToNowStrict(date, addSuffix, { locale, ...other });
}

export function fTimeSince(date) {
  return formatTimeSince(date);
}

export function getTimeSince(values) {
  return formatGetTimeSince(values);
}
