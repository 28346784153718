import { isEmpty, set } from 'lodash';
import { DATAGRID_GLOBALS } from '../utils/globals';
/* eslint-disable @typescript-eslint/no-explicit-any */

type StoreRecord = {
  id: string;
  [key: string]: any;
};

export function hashById(values: StoreRecord | StoreRecord[]): Record<string, StoreRecord> {
  const result: Record<number, StoreRecord> = {};
  const input = Array.isArray(values) ? values : [values];
  for (let i = 0; i < input.length; i++) {
    const value = input[i];
    result[value.id] = value;
  }
  return result;
}

type Resource = {
  data: any;
  meta?: Record<string, any>;
  links?: Record<string, any>;
  headers?: Record<string, any>;
};

export type Normalization = {
  raw?: boolean;
  skip?: boolean;
};

export function normalizeResource(input: Resource, normalization?: Normalization): Resource {
  // make use of destructuring to remove the headers from the response via the rest operator
  const { headers: _, ..._local } = input ?? { data: {}, meta: {}, links: {}, headers: {} };

  if (normalization?.raw) {
    return { data: { ..._local } };
  }

  if (normalization?.skip) {
    return { data: { data: _local.data, meta: { ..._local?.meta, links: { ..._local?.links } } } };
  }

  const output = hashById(_local.data);

  return { data: { ...output, meta: { ..._local?.meta, links: { ..._local?.links } } } };
}

type SortModel = {
  field: string;
  sort: 'asc' | 'desc';
  models?: string[];
};
type CursorPaginationState = {
  cursor: number;
  limit: number;
  page?: undefined;
};

export type OffsetPaginationState = {
  page: number;
  pageSize: number;
  cursor?: undefined;
  limit?: undefined;
};

type ServerPaginationState = {
  page?: string;
  cursor?: undefined;
  limit?: undefined;
};

type ApiParamFields = {
  [key: string]: string;
};

type GenerateApiParamsOptions = {
  filter?: string | Record<string, string>;
  include?: string;
  pagingMethod?: 'all' | 'cursor' | 'server' | 'offset';
  pagingState?: CursorPaginationState | OffsetPaginationState | ServerPaginationState;
  sortModels?: SortModel[];
  fields?: ApiParamFields;
};

export function generateApiParams({ filter, include, pagingMethod, pagingState, sortModels, fields }: GenerateApiParamsOptions) {
  let encodedPage = '';
  let jsonApiSort = '';

  (sortModels || []).forEach(({ field, sort, models }) => {
    // JSON:API sorts with key name prefixed by - if its descending
    if (sort === 'asc') {
      jsonApiSort = (models?.length ?? 0) > 1 ? `${field},` : `${field}`;
    } else {
      jsonApiSort = (models?.length ?? 0) > 1 ? `-${field},` : `-${field}`;
    }
  });

  switch (pagingMethod) {
    case 'all': {
      encodedPage = btoa('all::');
      break;
    }
    case 'cursor': {
      const { cursor = 0, limit = 50 } = pagingState ?? { ...DATAGRID_GLOBALS.defaultCursorPagination };
      // const offset = cursor === 0 ? cursor : cursor * limit;
      // TODO - There seems to be a bug in the Pagination for the users api because its acting like page
      const offset = cursor;
      encodedPage = btoa(`cursor:${offset}:${limit}`);
      break;
    }
    case 'server': {
      encodedPage = (pagingState?.page as string) ?? '';
      break;
    }
    default: {
      const { page, pageSize } = (pagingState as OffsetPaginationState) ?? { ...DATAGRID_GLOBALS.defaultOffsetPagination };
      const offset = page === 0 ? page : page * pageSize;
      const limit = pageSize;
      encodedPage = btoa(`offset:${offset}:${limit}`);
      break;
    }
  }

  const params: { [key: string]: string } = {};

  if (!isEmpty(encodedPage)) {
    set(params, 'page', encodedPage);
  }

  if (!isEmpty(sortModels)) {
    set(params, 'sort', jsonApiSort);
  }

  if (include) {
    set(params, 'include', include);
  }

  if (filter) {
    if (typeof filter === 'string') {
      set(params, 'filter', filter.replace(/=|\*|%/g, ''));
    }
    if (typeof filter === 'object') {
      const filterObj = Object.entries(filter).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value.replace(/=|\*|%/g, '');
        }
        return acc;
      }, {});
      set(params, 'filter', filterObj);
    }
  }

  if (fields) {
    set(params, 'fields', fields);
  }

  return params;
}
