import { useAuth0 } from '@auth0/auth0-react';
import { IconButtonAnimate, MenuPopover } from '@diagrid/cloud-ui-shared/components';
import { useIsMountedRef, useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MyAvatar } from 'src/components/MyAvatar';
import { SettingMode } from 'src/components/settings/drawer/SettingMode';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';
import { useTokens } from 'src/hooks/useTokens';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
  {
    label: 'App Info',
    linkTo: PATH_PAGE.buildInfo,
  },
];

// ----------------------------------------------------------------------

export function AccountPopover() {
  const { user, logout } = useAuth0();
  const { cleanup } = useTokens();
  const [_currentOrgId, _setCurrentOrgId] = useLocalStorage(`${user?.sub}/currentOrg`, user?.defaultOrganization);
  const { diagridAccount, isLoadingDiagridUser } = useContext(UsersRolesContext);
  const [_, setClosedByUser] = useLocalStorage('tourClosedByUser');

  const currentUser = useMemo(() => {
    return diagridAccount ?? {};
  }, [diagridAccount]);

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    try {
      cleanup();

      if (isMountedRef.current) {
        handleClose();
      }

      setClosedByUser(false);
      localStorage.clear();
      logout({ logoutParams: { returnTo: window.location.origin, federated: true } });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        disabled={isLoadingDiagridUser}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) =>
                theme.palette.mode === 'light' ? alpha(theme.palette.grey[100], 0.3) : alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ px: 3, py: 1 }}>
          <SettingMode height={32} iconSize="small" />
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
