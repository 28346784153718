import { ControlPlaneFormDataVersion } from 'src/types/ControlPlaneFormData';
import { DaprVersion } from '../types';

export const sortDaprVersions = (versions: DaprVersion[] | ControlPlaneFormDataVersion[]): DaprVersion[] => {
  if (!versions && versions?.length < 1) return [];
  if (!versions[0]?.version) return versions;

  return versions
    .slice()
    .sort((a, b) => a.version.replace(/\d+/g, conversion).localeCompare(b.version.replace(/\d+/g, conversion)))
    .reverse();
};

const conversion = (n) => (Number(n) + 100000).toString();
