import { PropsWithChildren } from 'react';
import { componentsOverrides, CustomShadows, generateTypography, getBreakpoints } from '@diagrid/cloud-ui-shared/utils/theme';
import { CssBaseline, PaletteOptions, TypeBackground } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider, Direction, Shadows } from '@mui/material/styles';

interface Palette extends PaletteOptions {
  background?: Partial<TypeBackground & { neutral: string }>;
}

type ThemeProviderProps = {
  typography: ReturnType<typeof generateTypography>;
  shadows: {
    light: Shadows;
    dark: Shadows;
  };
  customShadows: {
    light: CustomShadows;
    dark: CustomShadows;
  };
  themeMode: 'light' | 'dark';
  themeDirection: Direction;
  palette: {
    light: Palette;
    dark: Palette;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrideComponents?: any[];
};

export function ThemeProvider({
  children,
  typography,
  shadows,
  customShadows,
  themeMode,
  themeDirection,
  palette,
  overrideComponents,
}: PropsWithChildren<ThemeProviderProps>) {
  const isLight = themeMode === 'light';

  const theme = createTheme({
    palette: isLight ? palette.light : palette.dark,
    typography,
    breakpoints: getBreakpoints(),
    shape: { borderRadius: 8 },
    direction: themeDirection,
    shadows: isLight ? shadows.light : shadows.dark,
    customShadows: isLight ? customShadows.light : customShadows.dark,
    // TODO: find a way to type this properly.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
  theme.components = componentsOverrides(theme, overrideComponents);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
