import { useContext } from 'react';
import { TokensContext } from 'src/contexts/TokensContext';

export const useTokens = () => {
  const context = useContext(TokensContext);

  if (!context) throw new Error('Tokens context must be use inside TokensProvider');

  return context;
};
