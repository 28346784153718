import { useRef, useEffect, MutableRefObject } from 'react';

function useIsMountedRef(): MutableRefObject<boolean> {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}

export { useIsMountedRef };
