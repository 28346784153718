import { CloseSharp, InfoSharp } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

type ErrorSnackbarProps = {
  id: SnackbarKey;
  message: SnackbarMessage;
  title?: string;
};

const ErrorSnackbar = forwardRef<HTMLDivElement, ErrorSnackbarProps>((props, ref) => {
  const { id, message, title = 'Error', ...other } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert" {...other} className="SnackbarItem-variantError SnackbarItem-lessPadding">
      <Card sx={{ boxShadow: 'none' }}>
        <CardHeader
          elevation={0}
          title={title}
          avatar={<InfoSharp sx={{ color: 'error.main' }} />}
          action={
            <IconButton onClick={handleDismiss}>
              <CloseSharp />
            </IconButton>
          }
          sx={{
            p: 1,
          }}
        />
        <CardContent sx={{ p: 1 }}>{message}</CardContent>
      </Card>
    </SnackbarContent>
  );
});

export { ErrorSnackbar };
