import { LazyMotion } from 'framer-motion';
import { PropsWithChildren } from 'react';

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.js').then((res) => res.default);

export function MotionLazyContainer({ children }: PropsWithChildren) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
