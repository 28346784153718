import { OptionsObject, SnackbarMessage } from 'notistack';
import { ErrorSnackbar } from '../components';
import { ErrorResponse } from '../types';
import { t } from 'i18next';
import { GLOBAL_TIMEOUTS } from './globals';

type HandleErrorProps = {
  error: unknown;
  enqueueSnackbar: (message: SnackbarMessage, options: OptionsObject) => void;
  overrideErrorMessage?: string;
  overrideFallbackErrorMessage?: string;
};

export const handleError = ({ error, enqueueSnackbar, overrideErrorMessage = '', overrideFallbackErrorMessage = '' }: HandleErrorProps) => {
  console.error(error);

  if (overrideErrorMessage) {
    enqueueSnackbar(overrideErrorMessage, { variant: 'error' });
    return;
  }

  if (isErrorResponse(error) && error?.data.length > 0) {
    error.data.forEach((error) => {
      enqueueSnackbar(error.detail, {
        autoHideDuration: GLOBAL_TIMEOUTS.TOAST_AUTO_HIDE_DURATION_30S,
        variant: 'error',
        content: (key, message) => <ErrorSnackbar id={key} message={message} title={`${error.status} - ${error.title}`} />,
      });
    });
    return;
  }
  if (isError(error)) {
    enqueueSnackbar(t(error.message), { variant: 'error', autoHideDuration: GLOBAL_TIMEOUTS.TOAST_AUTO_HIDE_DURATION_30S });
    return;
  } else {
    enqueueSnackbar(overrideFallbackErrorMessage ?? t('notifications.unknownError.errors'), {
      variant: 'error',
      autoHideDuration: GLOBAL_TIMEOUTS.TOAST_AUTO_HIDE_DURATION_30S,
    });
  }
};

const isErrorResponse = (error: unknown): error is ErrorResponse => {
  return (error as ErrorResponse).data !== undefined;
};

const isError = (error: unknown): error is Error => {
  return (error as Error).message !== undefined;
};

export const extractErrorMessage = (error: unknown): string => {
  if (isErrorResponse(error) && error?.data.length > 0) {
    let msg = '';
    error.data.forEach((error, i) => {
      if (i === 0) {
        msg = `${error.detail}`;
      } else {
        msg = `${msg} ${error.detail}`;
      }
    });
    return msg;
  }
  if (isError(error)) {
    return error.message;
  }
  return '';
};
