import { Box, List, ListSubheader, useTheme } from '@mui/material';
import { useLocales } from 'src/hooks/useLocales';
import { NavConfig } from 'src/types/Navigation';
import { NavListRoot } from './NavList';

type NavSectionVerticalProps = {
  isCollapse?: boolean;
  navConfig: NavConfig[];
};

export function NavSectionVertical({ navConfig, isCollapse = false }: NavSectionVerticalProps) {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Box>
      {navConfig.map((group) => {
        if (group?.subheader) {
          return (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  ...theme.typography.overline,
                  paddingTop: theme.spacing(3),
                  paddingLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(1),
                  color: theme.palette.text.primary,
                  transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shorter,
                  }),

                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {translate(group.subheader)}
              </ListSubheader>

              {group.items.map((list) => (
                <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
              ))}
            </List>
          );
        }

        return (
          <List key={group.title} disablePadding sx={{ px: 2 }}>
            <NavListRoot key={group.title + group.path} list={group} isCollapse={isCollapse} />
          </List>
        );
      })}
    </Box>
  );
}
