import { Box, BoxProps } from '@mui/material';
import { m, MotionProps } from 'framer-motion';
import { varContainer } from './variants';

type MotionContainerProps = BoxProps & MotionProps;

export interface IMotionContainer extends MotionContainerProps {
  animate?: boolean;
  action?: boolean;
}

function MotionContainer({ animate, action = false, children, ...other }: IMotionContainer) {
  if (action) {
    return (
      <Box component={m.div} initial={false} animate={animate ? 'animate' : 'exit'} variants={varContainer()} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <Box component={m.div} initial="initial" animate="animate" exit="exit" variants={varContainer()} {...other}>
      {children}
    </Box>
  );
}

export { MotionContainer };
