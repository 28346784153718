type Overrides = {
  [x: string]: number;
};
export const getBreakpoints = (overrides?: Overrides) => ({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 1920,
    ...(overrides || {}),
  },
});
