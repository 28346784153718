export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey['500_32']}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      variants: [
        {
          props: { variant: 'menuItem' },
          style: {
            ':hover': {
              backgroundColor: 'unset !important',
            },
            color: theme.palette.text.primary,
            justifyContent: 'flex-start',
          },
        },
        {
          props: { variant: 'menuItemError' },
          style: {
            ':hover': {
              backgroundColor: 'unset !important',
            },
            color: theme.palette.error.main,
          },
        },
      ],
    },
  };
}
