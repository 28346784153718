import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { DownloadSharp } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import JsFileDownloader from 'js-file-downloader';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

type DownloadGenericFileProps = {
  fileName: string;
  fileUrl: string;
  isButton?: boolean;
  prependOrgIdToFileName?: boolean;
  onFinish?: () => void;
};

export function DownloadGenericFile({
  fileName,
  fileUrl,
  children,
  isButton = true,
  prependOrgIdToFileName = false,
  onFinish,
  ...other
}: PropsWithChildren<DownloadGenericFileProps>) {
  const [fileLoading, setFileLoading] = useState(false);
  const [token, setToken] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  const [currentOrgId] = useLocalStorage<string>(`${user?.sub}/currentOrg`, user.defaultOrganization);

  useEffect(() => {
    async function getToken() {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
    }
    getToken();
  }, [getAccessTokenSilently]);

  const neuFileName = useMemo(() => {
    let neuFileName = fileName;

    if (prependOrgIdToFileName) {
      neuFileName = `${currentOrgId}-${fileName}`;
    }

    return neuFileName;
  }, [fileName, currentOrgId, prependOrgIdToFileName]);

  const download = useMemo(
    () =>
      new JsFileDownloader({
        url: fileUrl,
        autoStart: false,
        contentType: false,
        filename: neuFileName,
        nativeFallbackOnError: true,
        onloadstart: () => setFileLoading(true),
        headers: [
          { name: 'Authorization', value: `Bearer ${token}` },
          { name: 'X-Diagrid-Orgid', value: currentOrgId },
        ],
      }),
    [neuFileName, fileUrl, token, currentOrgId]
  );

  const initiateDownload = () => {
    download
      .start()
      .then(() => {
        setFileLoading(false);
        if (onFinish) onFinish();
      })
      .catch(() => setFileLoading(false));
  };

  if (isButton) {
    return (
      <Button onClick={initiateDownload} variant="text" sx={{ color: 'inherit' }} startIcon={<DownloadSharp />} {...other}>
        {fileLoading ? <CircularProgress size={24} /> : children}
      </Button>
    );
  }

  return <Box onClick={initiateDownload}>{fileLoading ? <CircularProgress size={24} /> : children}</Box>;
}
