import { MotionContainer } from '@diagrid/cloud-ui-shared';
import { varBounce } from '@diagrid/cloud-ui-shared/components/animate/variants';
import { Container, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { PropsWithChildren, useContext } from 'react';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';
import { ForbiddenIllustration } from '../assets';

type RoleBasedGuardProps = {
  hasContent?: boolean;
  roles?: string[];
};

export default function RoleBasedGuard({ hasContent, roles, children }: PropsWithChildren<RoleBasedGuardProps>) {
  const { hasRoles } = useContext(UsersRolesContext);

  if (typeof roles !== 'undefined' && !hasRoles(roles)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" component="p">
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
