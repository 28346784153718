import { Box, IconButton, IconButtonProps } from '@mui/material';
import { m } from 'framer-motion';
import { forwardRef, PropsWithChildren, Ref } from 'react';

type IconButtonAnimateProps = {
  size?: 'medium' | 'small' | 'large';
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
} & IconButtonProps;

const IconButtonAnimate = forwardRef(
  ({ children, size = 'medium', color, ...other }: PropsWithChildren<IconButtonAnimateProps>, ref: Ref<HTMLButtonElement>) => (
    <AnimateWrap size={size}>
      <IconButton size={size} color={color} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

export { IconButtonAnimate };

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

type AnimateWrapProps = {
  size: 'small' | 'medium' | 'large';
};

function AnimateWrap({ size, children }: PropsWithChildren<AnimateWrapProps>) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
}
