import { MenuPopover, OrganizationContext, PRODUCT_PLANS, SWITCHER_BASE_PATHS } from '@diagrid/cloud-ui-shared';
import { Box, ListItemIcon, MenuItem, Stack } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Logo } from 'src/components/Logo';

type ProductPopoverProps = {
  isCollapse?: boolean;
};

const {
  mcp: { free },
} = PRODUCT_PLANS;

export function ProductSwitcher({ isCollapse }: ProductPopoverProps) {
  const { planName, allOrganizations } = useContext(OrganizationContext);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const isFreePlan = planName === free;

  const hasCraOrgs = useMemo(() => allOrganizations.some((org) => Boolean(org?.products?.cra?.enabled)), [allOrganizations]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!hasCraOrgs) {
        navigate('/');
        return;
      }
      setOpen(event.currentTarget);
    },
    [hasCraOrgs, navigate]
  );

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '100px',
      }}
    >
      <Logo isFreePlan={isFreePlan} onClick={handleOpen} disabledLink sx={{ ...(isCollapse && { pl: 0 }) }} />

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        arrow="top-center"
        anchorOrigin={{ vertical: 85, horizontal: 'center' }}
        onClose={handleClose}
        sx={{
          p: 1.5,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <MenuItem disableGutters dense key="conductor">
            <ListItemIcon sx={{ m: 0, py: 0.5 }}>
              <Logo
                href={SWITCHER_BASE_PATHS.conductor}
                product="conductor"
                onClick={handleClose}
                sx={{
                  maxHeight: '30px',
                  minHeight: '30px',
                }}
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem disableGutters dense key="catalyst">
            <ListItemIcon sx={{ m: 0, py: 0.5 }}>
              <Logo
                href={SWITCHER_BASE_PATHS.catalyst}
                external
                product="cra"
                sx={{
                  maxHeight: '30px',
                  minHeight: '30px',
                }}
              />
            </ListItemIcon>
          </MenuItem>
        </Stack>
      </MenuPopover>
    </Box>
  );
}
