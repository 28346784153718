import { useAuth0 } from '@auth0/auth0-react';
import { UserOrganization, parseIntakeRoute, shouldNavigateToUserSurvey } from '@diagrid/cloud-ui-shared';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';
import { PATH_PAGE } from 'src/routes/paths';
import { DIAGRID_ROLES, DIAGRID_ROLES_ORG_ADMIN } from 'src/utils/constants';

type RedirectUserSurveyProps = { currentOrg: UserOrganization };

export function RedirectUserSurvey({ currentOrg }: RedirectUserSurveyProps) {
  const { user } = useAuth0();
  const { diagridAccount } = useContext(UsersRolesContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      (diagridAccount?.roles?.includes(DIAGRID_ROLES.admin.id) || diagridAccount?.roles?.includes(DIAGRID_ROLES_ORG_ADMIN)) &&
      shouldNavigateToUserSurvey({ organization: currentOrg, userEmail: user?.email }) &&
      user
    ) {
      const intakeRoute = parseIntakeRoute(PATH_PAGE.userIntakeSurvey, user);
      navigate(intakeRoute, { replace: true });
    }
  }, [currentOrg, diagridAccount?.roles, navigate, user]);

  return <></>;
}
