import i18next from 'i18next';
import { clusterId } from 'src/contexts/constants/tourMocks';
import { TourToolTipContainer } from '../TourToolTipContainer';
import { TOUR_SETTINGS } from '../constants';

export const setAdvisoriesStep = (step, navigate, setStep, setTourState, submitCompletedUITours) => {
  switch (step) {
    case 0:
      navigate('/clusters', { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 300);
      break;
    case 1:
      navigate(`clusters/${clusterId}/detail/summary`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 1000);
      break;
    case 3:
      navigate(`clusters/${clusterId}/detail/summary`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 400);
      break;
    case 4:
      navigate(`clusters/${clusterId}/detail/advisor`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 5:
      navigate(`clusters/${clusterId}/detail/advisor?category=security`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 6:
      navigate(`clusters/${clusterId}/detail/advisor`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 7:
      navigate(`clusters/${clusterId}/detail/advisor?category=security`, { replace: true });
      setTourState({
        isSecurityAdvisorDetailOpen: false,
      });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 8:
      setTourState({
        isSecurityAdvisorDetailOpen: true,
      });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 9:
      submitCompletedUITours(TOUR_SETTINGS.ADVISORIES_FINISHED);
      setStep(step);
      break;
    default:
      setStep(step);
  }
};

export const advisoriesSteps = [
  {
    selector: `[data-id="${clusterId}"]`,
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.clusterList.title')}>
        {i18next.t('tour.advisoriesScenario.clusterList.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="cluster-summary"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.clusterSummary.title')}>
        {i18next.t('tour.advisoriesScenario.clusterSummary.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="edit-cluster-button"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.clusterEdit.title')}>
        {i18next.t('tour.advisoriesScenario.clusterEdit.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="cluster-insights"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.clusterInsights.title')}>
        {i18next.t('tour.advisoriesScenario.clusterInsights.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="advisor-page"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.advisorPage.title')}>
        {i18next.t('tour.advisoriesScenario.advisorPage.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="security-category"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.securityAdvisorTab.title')}>
        {i18next.t('tour.advisoriesScenario.securityAdvisorTab.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="sync-advisories-button"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.syncAdvisories.title')}>
        {i18next.t('tour.advisoriesScenario.syncAdvisories.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="security-list"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.securityAdvisor.title')}>
        {i18next.t('tour.advisoriesScenario.securityAdvisor.content')}
      </TourToolTipContainer>
    ),
  },

  {
    selector: '[data-tour="security-detail"]',
    position: (props) => [props.windowWidth / 2 - props.width - 20, 20],
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.securityRecommendation.title')}>
        {i18next.t('tour.advisoriesScenario.securityRecommendation.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="security-detail-impacted"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.advisoriesScenario.impactedResources.title')}>
        {i18next.t('tour.advisoriesScenario.impactedResources.content')}
      </TourToolTipContainer>
    ),
  },
];
