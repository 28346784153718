import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import enLocales from '../locales/en.json';
import { Language } from '../types';
import { merge } from 'lodash';

type LocalesConfigProps = {
  i18n: typeof i18n;
  defaultLang: Language;
  locales: {
    [key: string]: {
      translations: Record<string, unknown>;
    };
  };
};

export const generatei18nConfig = ({ i18n, defaultLang, locales }: LocalesConfigProps) => {
  const sharedLocales = {
    en: { shared: enLocales },
  };

  const resources = merge(locales, sharedLocales);

  i18n
    .use(i18nextPlugin)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: localStorage.getItem('i18nextLng') || defaultLang.value,
      fallbackLng: defaultLang.value,
      debug: false,
      ns: ['translations', 'shared'],
      defaultNS: 'translations',
      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
};
