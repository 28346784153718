import { filter, map } from 'lodash';
import { useMemo } from 'react';
import { useGetVersionsQuery } from 'src/redux/store';
import { DaprVersion, VersionsResponse } from 'src/types';
import { sortDaprVersions } from 'src/utils';

export function useGetDaprVersions({ withData = false }) {
  const { data: daprVersionData } = useGetVersionsQuery(withData);

  const daprVersions = useMemo(
    () =>
      map(filter((daprVersionData as VersionsResponse) ?? [], { kind: 'dapr' }), (v: DaprVersion) => ({
        ...v,
        version: v.name,
        data: v.data,
      })),
    [daprVersionData]
  );
  const sortedDaprVersions = useMemo(() => sortDaprVersions(daprVersions), [daprVersions]);

  return { daprVersions, sortedDaprVersions };
}
