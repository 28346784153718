export const LINKS = {
  clusterConnections: 'https://docs.diagrid.io/conductor/cluster-configuration/overview',
  community: 'https://diagrid.ws/diagrid-community',
  conductorFeedback: 'https://github.com/diagridio/conductor-feedback/issues/new/choose',
  conductorPreReqs: 'https://docs.diagrid.io/conductor/getting-started/prereqs',
  deleteClusterConnection: 'https://docs.diagrid.io/conductor/how-to-guides/operator-overview#delete-a-cluster-connection',
  docs: 'https://docs.diagrid.io/conductor',
  errorCodeMetricsSupport: 'https://docs.dapr.io/operations/observability/metrics/metrics-overview/#configuring-metrics-for-error-codes',
  googleCelSpec: 'https://github.com/google/cel-spec',
  highCardinalityMetrics: 'https://diagrid.ws/dapr-high-cardinality-metrics',
  limitations: 'https://docs.diagrid.io/conductor/insights/components/#known-limitations',
  mailToHelp: 'mailto:help@diagrid.io',
  mailToSales: 'mailto:sales@diagrid.io',
  mailToSupport: 'mailto:support@diagrid.io',
  marketing: 'https://www.diagrid.io/',
  meet: 'https://diagrid.ws/meet',
  planDetails: 'https://docs.diagrid.io/conductor/#available-plans',
  quickStart: 'https://docs.diagrid.io/conductor/getting-started/quickstart',
  quickStartSample: 'https://github.com/diagrid-labs/conductor-quickstart',
  releaseNotes: 'https://docs.diagrid.io/conductor/release-notes',
  reportIssue: 'https://github.com/diagridio/conductor-feedback/issues/new/choose',
  technicalSupport: 'https://diagrid.ws/conductor-technical-support',
  uptime: 'https://status.diagrid.io/',
  videos: 'https://diagrid.ws/conductor-videos',
};
