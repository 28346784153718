import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type ChartsState = {
  clickedLegendsHashTable: Record<string, string[]>;
};

const initialState: ChartsState = {
  clickedLegendsHashTable: {},
};

// apexCharts reducer is meant to be used to track chart states because we wrap our charts in an inView component to improve performance with the downside of losing state when the chart is out of view, things like legend items.
const slice = createSlice({
  name: 'apexCharts',
  initialState,
  reducers: {
    set(state, action) {
      const { chartId, legendItems } = action.payload;
      state.clickedLegendsHashTable[chartId] = legendItems;
    },
    reset(state, action) {
      const { chartId } = action.payload;
      state.clickedLegendsHashTable[chartId] = [];
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export function setChartItem(chartId: string, legendItems: string[]) {
  return () => {
    dispatch(slice.actions.set({ chartId, legendItems }));
  };
}

// ----------------------------------------------------------------------

export function resetChartItem(chartId: string) {
  return () => {
    dispatch(slice.actions.reset({ chartId }));
  };
}
