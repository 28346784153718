import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationProvider } from '@diagrid/cloud-ui-shared';
import { useCollapseDrawer } from '@diagrid/cloud-ui-shared/hooks';
import { Box, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { HubSpotWrapper } from 'src/components/HubSpotWrapper';
import { RedirectUserSurvey } from 'src/components/RedirectUserSurvey';
import { AllToursProviders } from 'src/components/tours/AllToursProviders';
import { AUTH0_API, HEADER, NAVBAR } from 'src/config';
import { TokensProvider } from 'src/contexts/TokensContext';
import { UserRolesProvider } from 'src/contexts/UsersRolesContext';
import { useLocales } from 'src/hooks/useLocales';
import { useFindQuery } from 'src/redux/store';
import { PATH_API } from 'src/routes/paths';
import { jsonApiClient } from 'src/utils/axios';
import { CUSTOM_CLAIMS } from 'src/utils/constants';
import { DashboardHeader } from './header';
import { NavbarVertical } from './navbar/NavbarVertical';

export default function DashboardLayout() {
  const { translate } = useLocales();
  const theme = useTheme();
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { isAuthenticated, user } = useAuth0();
  const { data: org } = useFindQuery({ type: 'userOrganizations', normalization: { raw: true } }, { skip: !isAuthenticated });
  const [open, setOpen] = useState(false);

  const currentOrgId = useMemo(() => {
    // something weird going on with useLocalStorage, so using localStorage directly
    const localOrgId = localStorage.getItem(`${user?.sub}/currentOrg`);
    let parsed;
    try {
      parsed = JSON.parse(localOrgId);
    } catch (err) {
      parsed = undefined;
    }

    return parsed || user[CUSTOM_CLAIMS.defaultOrganization];
  }, [user]);

  const currentOrg = useMemo(() => org?.data?.find((o) => o.id === currentOrgId) ?? {}, [currentOrgId, org]);
  const trialDate = useMemo(() => (currentOrg ? currentOrg?.accessUntil : undefined), [currentOrg]);

  const showsTrialDate = Boolean(trialDate);

  return (
    <OrganizationProvider
      currentProduct="mcp"
      auth0Apis={AUTH0_API}
      jsonApiClient={jsonApiClient}
      paths={PATH_API}
      translations={{ fallbackError: translate('notifications.errors.setDefaultOrg') }}
      useFindQuery={useFindQuery}
    >
      <UserRolesProvider>
        <TokensProvider>
          <AllToursProviders>
            <Box
              sx={{
                display: { lg: 'flex' },
                minHeight: { lg: 1 },
              }}
            >
              <RedirectUserSurvey currentOrg={currentOrg} />
              <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} trialDate={trialDate} />
              <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  paddingTop: `${HEADER.MOBILE_HEIGHT + (showsTrialDate ? 68 : 24)}px`,
                  paddingBottom: `${HEADER.MOBILE_HEIGHT + 24}px`,
                  [theme.breakpoints.down(768)]: {
                    paddingTop: `${HEADER.MOBILE_HEIGHT + (showsTrialDate ? 88 : 66)}px`,
                  },
                  [theme.breakpoints.down('sm')]: {
                    paddingTop: `${HEADER.MOBILE_HEIGHT + (showsTrialDate ? 88 : 16)}px`,
                  },
                  [theme.breakpoints.up('lg')]: {
                    paddingLeft: '36px',
                    paddingRight: '36px',
                    paddingTop: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + (showsTrialDate ? 68 : 24)}px`,
                    paddingBottom: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
                    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
                    transition: theme.transitions.create('margin-left', {
                      duration: theme.transitions.duration.shorter,
                    }),
                    ...(collapseClick && {
                      marginLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px`,
                    }),
                  },
                }}
              >
                <HubSpotWrapper>
                  <Outlet />
                </HubSpotWrapper>
              </Box>
            </Box>
          </AllToursProviders>
        </TokensProvider>
      </UserRolesProvider>
    </OrganizationProvider>
  );
}
