import { Logo as LogoShared } from '@diagrid/cloud-ui-shared/components';
import { BoxProps, LinkBaseProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { LinkProps } from 'react-router-dom';

type LogoProps = {
  href?: string;
  product?: 'cra' | 'conductor';
  disabledLink?: boolean;
  external?: boolean;
  sx?: SxProps<Theme>;
  isFreePlan?: boolean;
};

export function Logo({
  product = 'conductor',
  disabledLink = false,
  href = '/',
  external = false,
  isFreePlan = false,
  sx,
  ...props
}: LogoProps & Partial<BoxProps> & Partial<LinkBaseProps> & Partial<LinkProps>) {
  const logoPaths = {
    cra: {
      light: `${window.location.origin}/logo/CRA_iso_default.svg`,
      lightSmall: `${window.location.origin}/logo/CRA_iso_logo_default.svg`,
      dark: `${window.location.origin}/logo/CRA_iso_white.svg`,
      darkSmall: `${window.location.origin}/logo/CRA_iso_logo_default.svg`,
    },
    conductor: {
      light: `${window.location.origin}/logo/Conductor_Default.svg`,
      lightSmall: `${window.location.origin}/logo/iso_logo_default.svg`,
      dark: `${window.location.origin}/logo/Conductor_White.svg`,
      darkSmall: `${window.location.origin}/logo/iso_logo_default.svg`,
      lightFree: `${window.location.origin}/logo/Conductor_Default_Free.svg`,
      darkFree: `${window.location.origin}/logo/Conductor_White_Free.svg`,
    },
  };

  return (
    <LogoShared
      external={external}
      disabledLink={disabledLink}
      logoPaths={logoPaths[product]}
      alt={product === 'conductor' ? 'Diagrid Conductor Logo' : 'Diagrid Catalyst Logo'}
      href={href}
      isFreePlan={isFreePlan}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
        ...sx,
      }}
      {...(props as BoxProps & LinkBaseProps & LinkProps)}
    />
  );
}
