import { isEmpty, isNil } from 'lodash';

// This utility is used to avoid warnings on Select and Autocomplete selects, where the value is empty string, but there is no option with value of empty string.
// This would convert it to null which is an accepted value for Select and Autocomplete.
export const getValForEmptySelect = (value, isMultiple = false) => {
  const isEmptyVal = isNil(value) || isEmpty(value) || value === '';
  if (isEmptyVal) {
    return isMultiple ? [] : null;
  }
  return value;
};
