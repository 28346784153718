// @mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint } from '@mui/system';

type QueryType = 'up' | 'down' | 'between' | 'only';

function useResponsive(query: QueryType, key: Breakpoint, start?: Breakpoint, end?: Breakpoint) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start || ('' as Breakpoint), end || ('' as Breakpoint)));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  switch (query) {
    case 'up':
      return mediaUp;
    case 'down':
      return mediaDown;
    case 'between':
      return mediaBetween;
    case 'only':
      return mediaOnly;
    default:
      return null;
  }
}

export { useResponsive };
