import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { Language } from '../types';

type ThemeLocalizationProps = {
  currentLang: Language;
};

export function ThemeLocalization({ children, currentLang }: PropsWithChildren<ThemeLocalizationProps>) {
  const defaultTheme = useTheme();

  const theme = createTheme(defaultTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
