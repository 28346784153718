import { Avatar as MUIAvatar, SxProps, Theme, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

type AvatarProps = {
  color?: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  sx?: SxProps<Theme>;
  src?: string;
  [x: string]: unknown;
};

function Avatar({ color = 'default', children, sx, ...other }: PropsWithChildren<AvatarProps>) {
  const theme = useTheme();

  if (color === 'default') {
    return (
      <MUIAvatar sx={sx} {...other}>
        {children}
      </MUIAvatar>
    );
  }

  return (
    <MUIAvatar
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        ...sx,
      }}
      {...other}
    >
      {children}
    </MUIAvatar>
  );
}

export { Avatar };
