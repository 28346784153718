import { DarkModeSharp, LightModeSharp } from '@mui/icons-material';
import { CardActionArea, Grid2 as Grid, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import useSettings from '../../../hooks/useSettings';
import { BoxMask } from './BoxMask';

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey['500_12']}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

type SettingModeProps = {
  height?: number;
  iconSize?: 'small' | 'medium' | 'large';
};

export function SettingMode({ height, iconSize }: SettingModeProps) {
  const { themeMode, onChangeMode } = useSettings();

  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
      <Grid dir="ltr" container spacing={2.5}>
        {['light', 'dark'].map((mode, index) => {
          const isSelected = themeMode === mode;

          return (
            <Grid key={mode} size={6}>
              <BoxStyle
                sx={{
                  bgcolor: mode === 'light' ? 'common.white' : 'grey.800',
                  ...(isSelected && {
                    color: 'primary.main',
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                  height: height ?? 72,
                }}
              >
                {index === 0 ? <LightModeSharp fontSize={iconSize} /> : <DarkModeSharp fontSize={iconSize} />}
                <BoxMask value={mode} />
              </BoxStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
