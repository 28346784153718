import { isAfter, parseISO } from 'date-fns';
import { UserOrganization } from '../types';
import { isEmpty } from 'lodash';
import { PRODUCT_PLANS } from './globals';

type Props = {
  organization: UserOrganization;
  userEmail?: string;
  product?: string;
  forOrgsAfterDate?: Date;
};

export const shouldNavigateToUserSurvey = ({
  organization,
  userEmail,
  product = 'mcp',
  forOrgsAfterDate = new Date(2024, 2, 1),
}: Props) => {
  if (userEmail?.includes('diagrid.io') || window.location.host.includes('localhost') || window.Cypress) return false;
  if (isEmpty(organization)) return false;
  const orgPlanName = organization?.products?.[product]?.plan;
  let orgUserSurveyCompleted = false;

  if (product === 'cra') {
    orgUserSurveyCompleted = organization?.defaults?.inTakeSurveyCompletedCra ?? false;
  } else if (product === 'mcp') {
    orgUserSurveyCompleted = organization?.defaults?.inTakeSurveyCompleted ?? false;
  }

  if (orgUserSurveyCompleted) return false;

  if (product === 'mcp') {
    if (orgPlanName === PRODUCT_PLANS.mcp.trial) {
      const orgHasAccess =
        organization?.accessUntil &&
        new Date(organization.accessUntil) > new Date() &&
        isAfter(parseISO(organization.createdAt), new Date(2024, 0, 22));

      if (orgHasAccess && !orgUserSurveyCompleted) {
        return true;
      }

      return false;
    }
    if ((orgPlanName === PRODUCT_PLANS.mcp.free || orgPlanName === PRODUCT_PLANS.mcp.enterprise) && !orgUserSurveyCompleted) {
      if (orgPlanName === PRODUCT_PLANS.mcp.enterprise) {
        if (isAfter(parseISO(organization.createdAt), forOrgsAfterDate)) {
          return true;
        }

        return false;
      }

      return true;
    }
  }

  // TODO: there are more products to be added here
  if (product === 'cra') {
    if (!orgUserSurveyCompleted) {
      return true;
    }
  }

  return false;
};
