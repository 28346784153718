import { RumEvent, RumEventDomainContext, RumFetchResourceEventDomainContext, datadogRum } from '@datadog/browser-rum';
import { MotionLazyContainer } from '@diagrid/cloud-ui-shared';
import { CollapseDrawerProvider } from '@diagrid/cloud-ui-shared/contexts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import { FlagProvider } from '@unleash/proxy-client-react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { SettingsProvider } from './contexts/SettingsContext';
import './locales/i18n';
import { persistor, store } from './redux/store';
import './utils/highlight';

import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'simplebar-react/dist/simplebar.min.css';

import App from './App';
import { Auth0ProviderWithRedirectCallback } from './components/auth/Auth0ProviderWithRedirectCallback';
import { AUTH0_API, UNLEASH_CONFIG } from './config';
import { isProduction } from './utils/get-runtime-env';

// this key is designed to be public so there is no security risk by embedding it in the code, https://mui.com/x/introduction/licensing/#license-key-security
LicenseInfo.setLicenseKey('806afe49a1586d362dfc0b14538277e6Tz05NDc5OCxFPTE3NTMzOTc0ODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

if (isProduction()) {
  const userLanguage = navigator.language;
  datadogRum.init({
    applicationId: 'cbe4d2ba-4c22-4970-b251-4fc0888fb035',
    clientToken: 'pubb1d77e71a66eba5dc2709dfaa6988c29',
    site: 'datadoghq.com',
    service: 'conductor-ui',
    env: window.location.host,
    version: import.meta.env.VITE_GITHUB_REF_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event: RumEvent, context: RumEventDomainContext) => {
      // login required fires when the user's session is expired, we don't need to gather these errors
      if (event.type === 'error' && event.error.message.includes('Login required')) {
        return false;
      }

      // add additional context info to fetch events so we can see the request headers to help diagnose issues with the 401's that are happening
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        if (event.context) {
          event.context.requestHeaders = (context as RumFetchResourceEventDomainContext).requestInit?.headers;
        }
      }
      return true;
    },
  });

  datadogRum.setUserProperty('user_language', userLanguage);
}

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const DefaultComponent = () => {
  return (
    <SettingsProvider>
      <MotionLazyContainer>
        <Auth0ProviderWithRedirectCallback
          domain={AUTH0_API.domain}
          clientId={AUTH0_API.clientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: AUTH0_API.audience,
            // used by the auth0 actions to detect logins and signups initiated from the UI vs initiated from api.diagrid.io/login
            acr_values: 'mcp-ui-login',
            'ext-product': 'mcp',
          }}
          useRefreshTokens
          useRefreshTokensFallback
        >
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CollapseDrawerProvider>
                    <App />
                  </CollapseDrawerProvider>
                </LocalizationProvider>
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </Auth0ProviderWithRedirectCallback>
      </MotionLazyContainer>
    </SettingsProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '*',
    Component: DefaultComponent,
  },
]);

root.render(
  <StrictMode>
    {/* Client start is deferred. Client is started in App after the user loads so we can identify the userId. */}
    <FlagProvider config={UNLEASH_CONFIG} startClient={false}>
      <RouterProvider router={router} />
    </FlagProvider>
  </StrictMode>
);
