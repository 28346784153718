import { InputSelectIcon } from '@diagrid/cloud-ui-shared/components';

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
