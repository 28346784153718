import { Box, useTheme, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

type TourToolTipContainerProps = {
  title: string;
};
export function TourToolTipContainer({ title, children }: PropsWithChildren<TourToolTipContainerProps>) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        color: `${theme.palette.grey[800]} !important`,
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}
