import { DATAGRID_GLOBALS } from '@diagrid/cloud-ui-shared/utils';
import { generateTypography, getDefaultSettings, getShadows } from '@diagrid/cloud-ui-shared/utils/theme';

import { GREY, palette } from './palette';

const LIGHT_MODE = GREY[900];
const DARK_MODE = '#121C29';

export const typography = generateTypography('Public Sans, sans-serif');

export const { customShadows, shadows } = getShadows({ lightMode: LIGHT_MODE, darkMode: DARK_MODE, palette });

export const defaultSettings = getDefaultSettings({
  userTableRowCount: DATAGRID_GLOBALS.defaultPagination.pageSize,
});
