export const DATAGRID_GLOBALS = {
  defaultPagination: {
    page: 0,
    pageSize: 10,
  },
  defaultOffsetPagination: {
    count: 0,
    totalPages: 0,
    page: 0,
    pageSize: 10,
  },
  defaultCursorPagination: {
    cursor: 0,
    limit: 0,
  },
  defaultSortModel: { field: 'name', sort: 'asc' } as { field: string; sort: 'asc' | 'desc' },
  rowsPerPage: [10, 25, 50, 100],
};

export const CUSTOM_CLAIMS = {
  defaultOrganization: 'https://diagrid.io/defaultOrg',
  ssoUser: 'https://diagrid.io/ssoUser',
  rolesLoaded: 'https://diagrid.io/rolesLoaded',
};

export const SWITCHER_BASE_PATHS = (function () {
  const currentHost = window.location.host;

  if (currentHost.includes('localhost')) {
    return {
      conductor: 'http://localhost:3000',
      catalyst: 'http://localhost:4200',
    };
  } else if (currentHost.includes('dev.cloud.diagrid.io') || currentHost.includes('dev.diagrid.io')) {
    return {
      conductor: 'https://conductor.dev.diagrid.io',
      catalyst: 'https://catalyst.dev.diagrid.io',
    };
  } else if (currentHost.includes('stg.cloud.diagrid.io') || currentHost.includes('stg.diagrid.io')) {
    return {
      conductor: 'https://conductor.stg.diagrid.io',
      catalyst: 'https://catalyst.stg.diagrid.io',
    };
  } else {
    return {
      conductor: 'https://conductor.diagrid.io',
      catalyst: 'https://catalyst.diagrid.io',
    };
  }
})();

export const API_METHODS = {
  GET: 'GET',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST',
};
export const API_REQUESTS_HEADERS = {
  orgIdHeader: 'x-diagrid-orgid',
  errorCodeHeader: 'x-diagrid-error-code',
  errorDescHeader: 'x-diagrid-error-desc',
};

export const ERROR_CODES = {
  orgBlocked: 'org_blocked',
  noProductAccess: 'no_product_access',
};

export const GLOBAL_TIMEOUTS = {
  DEFAULT_RESOURCE_POLLING: 10000,
  DEFAULT_MANIFEST_REFRESH_IN_HOURS: 6,
  POLL_INTERVAL_1h: 60 * 60 * 1000,
  POLL_INTERVAL_5s: 5000,
  POLL_INTERVAL_30S: 30000,
  POLL_INTERVAL_1M: 60 * 1000,
  POLL_INTERVAL_30m: 30 * 60 * 1000,
  POLL_INTERVAL_5m: 5 * 60 * 1000,
  TOAST_AUTO_HIDE_DURATION: 5000,
  TOAST_AUTO_HIDE_DURATION_30S: 30000,
};

export const GLOBAL_IGNORED_METADATA_SCHEMAS = ['secretstores', 'configuration'];

export type GlobalAvailableTimespansProps = {
  ts?: string;
  start: string;
  end?: string;
  step: string;
  label?: string;
};

export const GLOBAL_AVAILABLE_TIMESPANS: {
  [key: string]: GlobalAvailableTimespansProps;
} = {
  '5m': {
    ts: '5m',
    start: '5:m',
    step: '10s',
    label: '5 minutes',
  },
  '30m': {
    ts: '30m',
    start: '30:m',
    step: '30s',
    label: '30 minutes',
  },
  '60m': {
    ts: '60m',
    start: '60:m',
    step: '1m',
    label: '1 hour',
  },
  '6h': {
    ts: '6h',
    start: '6:h',
    step: '2m',
    label: '6 hours',
  },
  '12h': {
    ts: '12h',
    start: '12:h',
    step: '4m',
    label: '12 hours',
  },
  '24h': {
    ts: '24h',
    start: '24:h',
    step: '8m',
    label: '24 hours',
  },
  '3d': {
    ts: '3d',
    start: '72:h',
    step: '12m',
    label: '3 days',
  },
  '7d': {
    ts: '7d',
    start: '168:h',
    step: '28m',
    label: '7 days',
  },
};

export const PRODUCT_PLANS = {
  mcp: {
    free: 'mcp:free',
    trial: 'mcp:trial',
    community: 'mcp:community',
    standard: 'mcp:standard',
    business: 'mcp:business',
    enterprise: 'mcp:enterprise',
  },
  cra: {
    community: 'cra:community',
    partner: 'cra:partner',
    standard: 'cra:standard',
  },
};
