import { Direction } from '@mui/material';
import { getThemeMode } from './getThemeMode';

const { themeMode, localThemeMode } = getThemeMode();

export const getDefaultSettings = (
  overrides = {}
): {
  themeMode: 'light' | 'dark';
  themeDirection: Direction;
  themeColorPresets: string;
  themeLayout: 'horizontal' | 'vertical';
  themeStretch: boolean;
  themeContrast: boolean;
  userTableRowCount: number;
  defaultListView: 'list' | 'grid';
} => ({
  themeMode,
  themeDirection: 'ltr' as Direction,
  themeColorPresets: localThemeMode === 'light' ? 'blueLightTheme' : 'blue',
  themeLayout: 'horizontal',
  themeStretch: false,
  themeContrast: false,
  userTableRowCount: 10,
  defaultListView: 'list',
  ...overrides,
});
