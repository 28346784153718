import axios from 'axios';

export function initializeClient({ baseURL }: { baseURL: string }) {
  if (!baseURL) {
    throw new Error('baseURL is required to initialize the client');
  }
  const jsonApiClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/vnd.api+json',
    },
  });

  jsonApiClient.interceptors.response.use(
    (response) => response,
    function (error, ...args) {
      console.error(`${error?.response?.statusText ?? 'Unknown Error Status'}: Code ${error?.response?.status ?? 'Unknown'}`, error);

      return Promise.reject(error);
    }
  );

  return jsonApiClient;
}
