import { createGradient } from '@diagrid/cloud-ui-shared/utils/theme';
import { alpha } from '@mui/material/styles';
import { PaletteOptions, TypeBackground } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface Palette {
    chart: Record<string, string[]>;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#CDFDDD',
  light: '#69F4B3',
  main: '#0BDDA3',
  dark: '#059F92',
  darker: '#025E6A',
};
const SECONDARY = {
  lighter: '#bbdffb',
  light: '#63b8f6',
  main: '#129af3',
  dark: '#007ad3',
  darker: '#004ba3',
};
const INFO = {
  lighter: '#D8F7FE', // 100
  light: '#8BDAFC', // 300
  main: '#3EA9F5', // 500
  dark: '#1F63B0', // 700
  darker: '#0B3175', // 900
};
const SUCCESS = {
  lighter: '#D5FDCD',
  light: '#69F46D',
  main: '#0bdd39',
  dark: '#059F44',
  darker: '#026A40',
};
const SUCCESS_LIGHT = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FEFDCB',
  light: '#FCF964',
  main: '#F6F100',
  dark: '#B1AC00',
  darker: '#AA8F00',
};
const ERROR = {
  lighter: '#FDD2CD',
  light: '#F46975',
  main: '#dd0b46',
  dark: '#9F054E',
  darker: '#6A0247',
};

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  '500_8': alpha('#919EAB', 0.08),
  '500_12': alpha('#919EAB', 0.12),
  '500_16': alpha('#919EAB', 0.16),
  '500_24': alpha('#919EAB', 0.24),
  '500_32': alpha('#919EAB', 0.32),
  '500_48': alpha('#919EAB', 0.48),
  '500_56': alpha('#919EAB', 0.56),
  '500_80': alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#3EA9F5', '#1F63B0', '#0B3175', '#D8F7FE', '#8BDAFC'],
  green: ['#0BDDA3', '#059F92', '#025E6A', '#CDFDDD', '#69F4B3'],
  yellow: ['#F6F100', '#B1AC00', '#AA8F00', '#FEFDCB', '#FCF964'],
  red: ['#DD0B46', '#9F054E', '#6A0247', '#FDD2CD', '#F46975'],
};

const COMMON = {
  common: { black: '#121C29', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff', diagridGreen: '#0BDDA3' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY['500_24'],
  action: {
    hover: GREY['500_8'],
    selected: GREY['500_16'],
    disabled: GREY['500_80'],
    disabledBackground: GREY['500_24'],
    focus: GREY['500_24'],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

interface Palette extends PaletteOptions {
  background?: Partial<TypeBackground & { neutral: string }>;
}

export const palette: {
  light: Palette;
  dark: Palette;
} = {
  light: {
    ...COMMON,
    success: { ...SUCCESS_LIGHT, contrastText: GREY[800] },
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY['500_16'] },
    action: { active: GREY[500], ...COMMON.action },
  },
};
